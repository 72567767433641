import { LoginPage } from './Pages/LoginPage/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { LoginCallbackPage } from './Pages/LoginCallBackPage/LoginCallbackPage';
import { PageNotFoundPage } from './Pages/PageNotFound/PageNotFound';
// import { ContractAnalyticPage } from './Pages/ContractAnalyticPage/ContractAnalyticPage';
import { CreateAPIKeyPage } from './Pages/CreateAPIKeyPage/CreateAPIKeyPage';
import { EmptyLayout } from './Layouts/EmptyLayout/EmptyLayout';
import { MainLayout } from './Layouts/MainLayout/MainLayout';
import { ManageProjectPage } from './Pages/ManageProjectPage/ManageProjectPage';

import { HomePage } from './Pages/HomePage/HomePage';
import { ProjectListPage } from './Pages/ProjectListPage/ProjectListPage';
import { UsersPage } from './Pages/UsersPage/UsersPage';
import { UserProjectsPage } from './Pages/UserProjectsPage/UserProjectsPage';

import { AuthenticationGuard } from "../framework/components/authentication-guard";
import { ContractsAnalyticsPage } from './Pages/ContractsAnalyticsPage/ContractsAnalyticsPage';
import { CertificateAnalyticsPage } from './Pages/CertificateAnalyticsPage/CertificateAnalyticsPage';
import { BlockchainControlPage } from './Pages/BlockchainControlPage/BlockchainControlPage';
import { ScannersControlPage } from './Pages/ScannersControlPage/ScannersControlPage';

export const Kernel: React.FC = () => {
  return (

      <Routes>
        <Route path="/" 
          // element={<MainLayout />}>
          element={<AuthenticationGuard component={MainLayout} />} >
          <Route path="/" 
           element={<AuthenticationGuard component={HomePage} />}
          // element={<HomePage />} 
          />
           <Route path="home" 
           element={<AuthenticationGuard component={HomePage} />}
          // element={<HomePage />} 
          />
          <Route path="create-api-key" 
          element={<AuthenticationGuard component={CreateAPIKeyPage} />}
          // element={<CreateKeyPage />}
           />
          <Route path="users" 
            element={<AuthenticationGuard component={UsersPage} />}
          // element={<UsersPage />} 
          />
          <Route
            path="users/projects/:customerId/:email"
            element={<AuthenticationGuard component={UserProjectsPage} />}
            // element={<UserContractsPage />}
          />

          {/* Contract Creation */}
          {/* <Route
            path="contracts/new"
            element={<ManageContractPage />}
          /> */}

          <Route
            path="projects/new/:customerId/:email"
            element={<AuthenticationGuard component={ManageProjectPage} />}
            //element={<ManageContractPage />}
          />
          {/* Contract Edition */}
          <Route
            path="projects/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageProjectPage} />}
           // element={<ManageContractPage />}
          />
          <Route
            path="projects/blockchain/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageProjectPage} />}
            // element={<ManageContractPage />}
          />
          <Route
            path="projects/mezzanine/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageProjectPage} />}
            // element={<ManageContractPage />}
          />
          {/* Contract List */}
          <Route path="projects"
            element={<AuthenticationGuard component={ProjectListPage} />}
          //  element={<BlockchainListPage />}
            />

          {/* Contract Analytics - Not Yet Released */}
        <Route path="contractAnalytics"
          element={<AuthenticationGuard component={ContractsAnalyticsPage} />} />

        {/* Certificate Analytics - Not Yet Released */}
        <Route path="certificateAnalytics"
          element={<AuthenticationGuard component={CertificateAnalyticsPage} />} />

        <Route path="blockchainAdmin"
          element={<AuthenticationGuard component={BlockchainControlPage} />} />

        <Route path="scanners"
          element={<AuthenticationGuard component={ScannersControlPage} />} />

          {/* <Route
            path="contracts/:id/:name/analytics"
            element={<AuthenticationGuard component={ContractAnalyticPage} />}
            // element={<ContractDetailsPage />}
          /> */}

        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    
  );
};