import axios, { AxiosResponse } from 'axios';
import { configuration } from '../../config/configuration';
// import _ from 'lodash';
import { User } from '../data/user';
import {
  CreateScannerArgs,
  DeployScannerArgs,
  ScannerSiteType,
  DeploymentRecord,
  ScannerResponse
} from '../data/scanner-responses';

export class DashboardApiService {
  private accessToken: string;
  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }
  private buildHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };
  }

  //------------------------------------------------
  // New users API's
  public async inviteUser(email: string, fullName: string, publicName: string,isCertAdmin:boolean) {
    const response = await axios.post(
      configuration.api.apiUrl + '/users',
      {
        fullName: fullName,
        publicName: publicName,
        email: email,
        isCertAdmin: isCertAdmin
      },
      this.buildHeaders()
    );
    console.log(response);

    console.log('response', response.data);
    return response.data;
  }
  public async updateUser(id: string, publicName: string) {
    const response = await axios.put(
      configuration.api.apiUrl + '/users',
      {
        id: id,
        nickname: publicName,
      },
      this.buildHeaders()
    );
    console.log(response);

    console.log('response', response.data);
    return response.data;
  }
  public async getUsers(): Promise<User[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/users',
      this.buildHeaders()
    );
    return response.data;
  }
  public async fetchApiKeys(): Promise<string[]> {
    // console.log('fetchApiKeys', configuration.api.apiUrl);
    const response = await axios.get(
      configuration.api.apiUrl + '/api-key',
      this.buildHeaders()
    );

    if (response.status === 200 && response.data.success) {
      // const result = _.map(response.data.data.items, x => x.value);
      return [response.data.data];
    }
    return [];
  }

  public async createAdminUserKey(
    name: string,
    email?: string
  ): Promise<AxiosResponse> {
    const url = configuration.api.apiUrl + '/api-key';
    const data = {
      name,
      email,
      type: configuration.mentaport.adminCustomerType,
    };

    const response = await axios.post(url, data, this.buildHeaders());
    console.log(response);
    return response?.data?.final?.data?.apiKeyId;
  }
  //------------------------------------------------ 
  // Projects API's (ADMIN ONLY)
  public async activateProject(
    contractId: string,
    projectId: string,
    customerId: string
  ): Promise<boolean> {
    const url = configuration.api.apiUrl + '/helpers/project/activate';
    const data = {
      contractId,
      projectId,
      customerId,
    };

    const response = await axios.post(url, data, this.buildHeaders());
    console.log(response);
    if(response.status !== 200) {
      return false
    }
    const resJson = response?.data?.data;
    console.log('resJson', resJson);
    return resJson.status;
  }

  //---------------------------------------------------------
  // Scanners API's
  public async createNewScanner(data: CreateScannerArgs) {
    const response = await axios.post(
      configuration.api.apiUrl + '/scanners/create',
      data,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async deployScanner(
    data: DeployScannerArgs
  ): Promise<ScannerResponse> {
    const response = await axios.post(
      configuration.api.apiUrl + '/scanners/deploy',
      data,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async getDeploymentStatus(
    deploymentId: string
  ): Promise<DeploymentRecord> {
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/deployments/' + deploymentId,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async getDeploymentsByOwner(
    owner: string
  ): Promise<DeploymentRecord[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/deployments/owner/' + owner,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async getDeploymentsByInstance(
    instanceId: string
  ): Promise<DeploymentRecord[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/deployments/instance/' + instanceId,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async getDeploymentsBySite(
    site: ScannerSiteType
  ): Promise<DeploymentRecord[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/deployments/site/' + site,
      this.buildHeaders()
    );
    console.log(response);
    return response?.data?.data?.data;
  }
  public async getScannerByInstance(
    instanceId: string
  ): Promise<ScannerResponse[]> {
    console.log('instanceId', instanceId);
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/' + instanceId,
      this.buildHeaders()
    );
    console.log(response.data);
    return [...response?.data?.data?.data];
  }
  public async getScannerBySite(
    site: ScannerSiteType
  ): Promise<ScannerResponse[]> {
    console.log('site', site);
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/site/' + site,
      this.buildHeaders()
    );
    console.log('site response', response, response.data);
    return response?.data?.data?.data;
  }
  public async getScannerByOwner(owner: string): Promise<ScannerResponse[]> {
    console.log('owner', owner);
    const response = await axios.get(
      configuration.api.apiUrl + '/scanners/owner/' + owner,
      this.buildHeaders()
    );
    console.log(response.data);
    return response?.data?.data?.data;
  }
  public async deleteInstanceByInstanceId(instanceId: string) {
    console.log('instanceId', instanceId);
    const response = await axios.delete(
      configuration.api.apiUrl + '/scanners/' + instanceId,
      this.buildHeaders()
    );
    console.log(response.data);
    return response?.data?.data?.data;
  }
}
