import {
  Badge,
  Button,
  Card,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import './ProjectListPage.scss';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  IResults,
  ICertificateProject,
  ContractStatus,
  ContractEnvironment,
} from '@mentaport/certificates-projects';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { getPlaceHolder } from '../../helpers/list-helper';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useState } from 'react';
import {
  ProjectEnvironmentContent,
  ProjectListComponent,
} from '../../components/commons/ProjectListComponent/ProjectListComponent';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';
import { useDashboardApi } from '../../hooks/use-dashboard-api';

export const ProjectListPage = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const mentaport = useMentaport();
  const dialog = useDialog();
  const apiClient = useDashboardApi();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activationLoading, setActivationLoading] = useState(false);
  const [lastClickedProject, setLastClickedProject] = useState<ICertificateProject>();
  
  const [projectsResponse, projectsResponseLoading, setProjects] =
    useDataFetcher<IResults<ICertificateProject[]>>({
      serviceCall: () => {
        return mentaport?.getCertificateProjects();
      },
      dependencies: [mentaport],
      conditionForExecution: mentaport !== undefined,
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an error fetching the projects.'
        );
        console.error(error);
      },
    });

  const handleActivate = async (project: ICertificateProject) => {
    setLastClickedProject(project);
    // if mezzanine:
    // if(contract.environment === ContractEnvironment.Mezzanine){
    //   await handleMezzanineActivate(contract);
    //   trackEvent(AnalyticEvent.MakeContractActive, contract);
    //   return;
    // }
    onOpen();
  };

  const handlePauseProject = async (project: ICertificateProject) => {
    try {
      trackEvent(AnalyticEvent.PauseContract, project);
      const result = await mentaport?.pauseProject(project.contractId, project.projectId);
      if (result?.status === true) {
        updateProjectStatus(project, ContractStatus.Paused);
        dialog.notify(
          MessageStatus.Success,
          'Projects',
          'The project has been paused'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an issue pausing the project'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Projects',
        'There was an error pausing the project'
      );
    }
  };

  const handleResumeProject = async (project: ICertificateProject) => {
    try {
      const result = await mentaport?.resumeProject(project.contractId, project.projectId);
      if (result?.status === true) {
        trackEvent(AnalyticEvent.ResumeProject, project);
        updateProjectStatus(project, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Project',
          'The project has been resumed'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Project',
          'There was an issue resuming the project'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Project',
        'There was an error resuming the project'
      );
    }
  };

  const updateProjectStatus =async (
    project: ICertificateProject,
    newStatus: ContractStatus
  ) => {
    if (projectsResponse?.data === undefined) {
      return;
    }
    trackEvent(AnalyticEvent.UpdateProject, project);
    const contractsData = projectsResponse?.data;
    const index = _.findIndex(
      contractsData,
      x => x.projectId === project.projectId
    );
    if (index === -1) {
      return;
    }
    // console.log("updateContractStatus",  contractsData[index].customerId)
    // console.log("updateContractStatus", contract.customerId)
    contractsData[index].status = newStatus;
   // contractsData[index].customerId = contract.customerId;
    await setProjects({
      status: true,
      statusCode: 205,
      message: 'Project Activated ',
      data: contractsData,
    });
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={'Type of Activation'}
      >
        <Flex direction="column">
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Recommended</Badge>
            <Text fontSize="lg">On Registry</Text>
            <Text fontSize="m">
              Activate directly in Mentaport Infrastructure
            </Text>
            <Button variant="mint" onClick={handleActivateOnRegistry}>
              Activate
            </Button>
          </Card> 
        </Flex>
      </DrawerDialog>
    );
  };


  const handleActivateOnRegistry = async () => {
    try {
      setActivationLoading(true);
      if (lastClickedProject === undefined) {
        return;
      }
      onClose();
      console.log('handleActivateOnRegistry', lastClickedProject)
      console.log( lastClickedProject.customerId)
      const result = await apiClient?.activateProject(lastClickedProject.contractId, lastClickedProject.projectId, lastClickedProject.customerId);
      if (result === true) {
        trackEvent(AnalyticEvent.ActivateProject, lastClickedProject);
        updateProjectStatus(lastClickedProject, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Projects',
          'The project has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an issue activating the project'
        );
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message ?? "Error in backend."
      dialog.notify(
        MessageStatus.Error,
        'Projects',
        errorMessage
      );
    } finally {
      setActivationLoading(false);
    }
  };

  const getData = (): ProjectEnvironmentContent[] => {
    const groups = _.groupBy(projectsResponse?.data ?? [], x => x.environment);
    return [
      {
        projects: groups[ContractEnvironment.Mainnet] ?? [],
        environmentType: ContractEnvironment.Mainnet,
      },
      {
        projects: groups[ContractEnvironment.Testnet] ?? [],
        environmentType: ContractEnvironment.Testnet,
      },
      {
        projects: groups[ContractEnvironment.Mezzanine] ?? [],
        environmentType: ContractEnvironment.Mezzanine,
      },
      {
        projects: groups[ContractEnvironment.Localhost] ?? [],
        environmentType: ContractEnvironment.Localhost,
      },
     
    ];
  };

  return (
    <Flex flexDirection="column" height={'100%'} width="100%">
      {renderSideDrawer()}
      <ProjectListComponent
        managementPageProps={{
          title: 'Projects',
          buttons: [],
          path: '',
        
          hideBackButton: true,
          isLoading: projectsResponseLoading,
          placeholder: getPlaceHolder(),
          breadcrumbs: [],
          previousLocation: Routes.Home,
        }}
        complexActivationLoading={activationLoading || isOpen}
        environments={getData()}
        onEditProject={(project: ICertificateProject) => {
          navigate(`/projects/edit/${project.projectId}/${project.customerId}`);
        }}
        onPauseProject={(project: ICertificateProject): Promise<void> => {
          return handlePauseProject(project);
        }}
        onResumeProject={(project: ICertificateProject): Promise<void> => {
          return handleResumeProject(project);
        }}
        onActivateProject={(project: ICertificateProject): Promise<void> => {
          return handleActivate(project);
        }}
        onShowContractAnalytics={(
          contractId: string,
          contractName: string
        ): void => {
          trackEvent(AnalyticEvent.NavigationToContractAnalytics, {
            contractId,
            contractName,
          })
          navigate(
            `/contracts/${contractId}/${contractName}/analytics`
          );
        }}
      />
    </Flex>
  );
};

