import { useCallback, useEffect, useMemo, useState } from 'react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { getPlaceHolder } from '../../helpers/list-helper';
import {
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import './BlockchainControlPage.scss';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  IContract,
  IContractCreate,
  IUpdateContract,
  BlockchainTypes,
  ContractStatus,
  ContractEnvironment,
  ContractType
} from '@mentaport/supplement';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { LocalIcons } from '../../assets/icons/icons';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';

type SelectOption = {
  id: string;
  title: string;
};
type SelectData = {
  placeholder: string;
  options: SelectOption[];
};


const BlockChainTypes: SelectData = {
  placeholder: 'Blockchain',
  options: [
    {
      id: 'base',
      title: 'Base',
    },
    {
      id: 'none',
      title: 'None',
    }
  ],
};

// const EnvironmentTypes: SelectData = {
//   placeholder: 'Environment',
//   options: [
//     {
//       id: 'testnet',
//       title: 'testnet',
//     },
//     {
//       id: 'mainnet',
//       title: 'mainnet',
//     },
//   ],
// };

const EnvironmentTypesKeys = Object.values(ContractEnvironment)

const contractTypeKeys = Object.values(ContractType)

interface CustomContractCellRendererProps extends CustomCellRendererProps {
  onMakeMainClick: (data: IContract) => void;
  onPauseOrActivate: (data: IContract) => void;
  onArchive: (data: IContract) => void;
  sendingRequest?: "makeMain" | "pause/active" | "archive";
}

const CustomCellRenderer: React.FC<CustomContractCellRendererProps> = ({
  value,
  data,
  sendingRequest,
  onMakeMainClick,
  onPauseOrActivate,
  onArchive,
}) => {
  const parsedData = data as IContract;
  const isArchived = parsedData?.status === "Archive";
  const isMain = Boolean(parsedData.isMain ? parsedData.isMain : false) ;
  
  const isPaused = parsedData?.status === "Paused";
  const isUnavailable = !!sendingRequest || isArchived;
  const ActivateButtonText = sendingRequest === "pause/active" ? "Processing..." : isPaused ? "Activate" : "Pause";

  return (
    <HStack width={400} height={"100%"} alignItems={"center"}>
      <Button className={`Button Option ${isMain || isUnavailable ? "Disabled" : ""}`} onClick={onMakeMainClick.bind(this, parsedData)} disabled={isMain || isUnavailable}>
        {sendingRequest === "makeMain" ? "Processing..." : "Make Main"}
      </Button>
      <Button
        className={`Button Option ${isPaused ? 'Green' : 'Warning'} ${isUnavailable ? 'Disabled' : ''}`}
        onClick={onPauseOrActivate.bind(this, parsedData)}
        disabled={isUnavailable}
      >{ActivateButtonText}</Button>
      <Button
        className={`Button Option Red ${isUnavailable ? 'Disabled' : ''}`}
        onClick={onArchive.bind(this, parsedData)}
        disabled={isUnavailable}
      >
        {sendingRequest === "archive" ? "Processing..." : "Archive"}
      </Button>
    </HStack>
  );
};

export const BlockchainControlPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mentaport = useMentaport();

  const { trackEvent } = useAnalytics();

  /* Deploying Contract Variables */
  const [activateDeployedContract, setActivateDeployedContract] =
    useState(false);
  const [deployedContractIsMain, setDeployedContractIsMain] = useState(false);
  const [deployedContractType, setDeployedContractType] = useState<ContractType>(ContractType.Certificates);
  const [deployedcontractBlockchain, setDeployedContractBlockchain] =
    useState<BlockchainTypes>(BlockchainTypes.Base);
  const [deployedContractEnvironment, setDeployedContractEnvironment] =
    useState<ContractEnvironment>(ContractEnvironment.Testnet);

  /* Add New contract Variables */
  const [newContractIsMain, setNewContractIsMain] = useState(false);
  const [newContractType, setNewContractType] = useState<ContractType>(ContractType.Certificates);
  const [newcontractBlockchain, setNewContractBlockchain] =
    useState<BlockchainTypes>(BlockchainTypes.Base);
  const [newContractEnvironment, setNewContractEnvironment] =
    useState<ContractEnvironment>(ContractEnvironment.Testnet);
  const [newDeployContractAddress, setNewDeployContractAddress] = useState<string>('');
  const [newDeployContractUri, setNewDeployContractUri] = useState<string>('');
  const [newDeployContractName, setNewDeployContractName] = useState<string>('');
  const [newDeployContractSymbol, setNewDeployContractSymbol] = useState<string>('');
  const [newContractAddress, setNewContractAddress] = useState<string>('');
  const [newContractName, setNewContractName] = useState<string>('');
  const [certificateContracts, setCertificateContracts] = useState<Object[]>(
    []
  );
  const [rewardsContracts, setRewardsContracts] = useState<Object[]>([]);

  const [submittingPreexistingContract, setSubmittingPreexistingContract] = useState(false);
  const [submittingDeployContract, setSubmittingDeployContract] = useState(false);
  const [error, setError] = useState<string>('');
  // make the error message disappear after 5 seconds but the timer resets if the error message changes
  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
    }, 4 * 1_000);

    return () => clearTimeout(timer);
  }, [error]);

  const fetchContracts = useCallback(async () => {
    if (mentaport) {
      const [certificateData, rewardsData] = await Promise.allSettled([
        mentaport.getContracts(ContractType.Certificates),
        mentaport.getContracts(ContractType.Rewards),
      ]);
      if (certificateData.status === 'fulfilled') {
        setCertificateContracts(certificateData?.value?.data!.map((contract: IContract) => {
          return {
            ...contract,
            isMain: contract.isMain ? contract.isMain : false,
          }
        }));
      }
      if (rewardsData.status === 'fulfilled') {
        setRewardsContracts(rewardsData?.value?.data!.map((contract: IContract) => {
          return {
            ...contract,
            isMain: contract.isMain ? contract.isMain : false,
          }
        }));
      }
    }
  }, [mentaport]);

  useEffect(() => {
    if (mentaport)
      fetchContracts();
  }, [fetchContracts, mentaport])

  /* Deploy Contract Methods */
  const onChangeDeployedContractType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractType(event.target.value as ContractType);
    },
    [setDeployedContractType]
  );

  const onChangeDeployedContractBlockChain = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractBlockchain(event.target.value as BlockchainTypes);
    },
    [setDeployedContractBlockchain]
  );

  const onChangeDeployedContractEnvironment = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractEnvironment(event.target.value as ContractEnvironment);
    },
    [setDeployedContractEnvironment]
  );

  const onChangeDeployedContractIsMain = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeployedContractIsMain(event.target.checked);
    },
    [setDeployedContractIsMain]
  );

  const onChangeDeployedContractActivate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setActivateDeployedContract(event.target.checked);
    },
    [setActivateDeployedContract]
  );

  const isValidLink = (link: string) => {
    if (!link) return false;
    return link.startsWith('http://') || link.startsWith('https://');
  }

  const isValidContractAddress = (address: string) => {
    if (!address) return false;
    return address.startsWith('0x');
  }

  /* New Contract Methods */
  const onChangeNewContractType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractType(event.target.value as ContractType );
    },
    [setNewContractType]
  );

  const onChangeNewContractBlockChain = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractBlockchain(event.target.value as BlockchainTypes);
    },
    [setNewContractBlockchain]
  );

  const onChangeNewContractEnvironment = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractEnvironment(event.target.value as ContractEnvironment);
    },
    [setNewContractEnvironment]
  );

  const onChangeNewContractIsMain = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractIsMain(event.target.checked);
    },
    [setNewContractIsMain]
  );

  const onChangeDeployContractName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractName(event.target.value.substring(0, 20));
    },
    [setNewDeployContractName]
  );

  const onChangeDeployContractSymbol = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractSymbol(event.target.value.trim().slice(0, 4).toUpperCase());
    },
    [setNewDeployContractSymbol]
  );

  const onChangeNewDeployContractAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractAddress(event.target.value);
    },
    [setNewDeployContractAddress]
  );

  const onChangeNewContractAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractAddress(event.target.value);
    },
    [setNewContractAddress]
  );

  const onChangeNewContractName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractName(event.target.value);
    },
    [setNewContractName]
  );

  const onChangeDeployContractUri = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractUri(event.target.value);
    },
    [setNewDeployContractUri]
  );

  const resetDeployContractVariables = useCallback(() => {
    setDeployedContractBlockchain(BlockchainTypes.Base);
    setDeployedContractEnvironment(ContractEnvironment.Testnet);
    setDeployedContractIsMain(false);
    setDeployedContractType(ContractType.Certificates);
    setActivateDeployedContract(false);
    setNewDeployContractUri('');
    setNewDeployContractName('');
    setNewDeployContractSymbol('');
  }, []);

  const resetNewContractVariables = useCallback(() => {
    setNewContractAddress('');
    setNewContractBlockchain(BlockchainTypes.Base);
    setNewContractName('');
    setNewContractEnvironment(ContractEnvironment.Testnet);
    setNewContractIsMain(false);
    setNewContractType(ContractType.Certificates);
  }, []);

  const fetchDataAndReset = useCallback(() => {
    resetDeployContractVariables();
    resetNewContractVariables();
    fetchContracts();
  }, [resetDeployContractVariables, resetNewContractVariables, fetchContracts]);

  const onSubmitDeployContract = useCallback(async () => {
    if (submittingDeployContract) {
      return;
    }
    if (!deployedContractEnvironment || !deployedcontractBlockchain || !deployedContractType) {
      setError('Please select an environment, blockchain, and contract type');
      return;
    }
    if (!newDeployContractName ) {
      setError('Please enter a contract name');
      return;
    }
    if (deployedContractType === 'certificates') {
      if (!newDeployContractSymbol) {
        setError('Please enter a contract symbol');
        return;
      }
    } else {
      if (!newDeployContractUri || !isValidLink(newDeployContractUri)) {
        setError('Please enter a valid contract URI');
        return;
      }
    }

    // Create and deploy a new the contract
    const deployData: IContractCreate = {
      name: newDeployContractName.trim(),
      contractType: deployedContractType,
      blockchain: deployedcontractBlockchain,
      environment: deployedContractEnvironment,
      isMain: deployedContractIsMain,
      status: activateDeployedContract ? ContractStatus.Active : ContractStatus.Paused,
    };

    if (deployedContractType === "certificates") {
      Object.assign(deployData, {
        symbol: newDeployContractSymbol.trim(),
      });
    } else {
      Object.assign(deployData, {
        rewardsContractUri: newDeployContractUri,
      });
    }

    //console.log('deployData', deployData);
    try {
      setSubmittingDeployContract(true);
      const response = await mentaport?.createNewContract(deployData);
      console.log('deploy response', response);
      if (!response || !response.status) {
        throw new Error('Error deploying contract');
      }
      trackEvent(AnalyticEvent.DeployNewContract, {
        ...deployData
      });
      await fetchDataAndReset();
    } catch (error) {
      console.log('error', error);
      setError('Error deploying contract');
    } finally {
      setSubmittingDeployContract(false);
    }

  }, [ submittingDeployContract,
      deployedContractEnvironment,
      deployedcontractBlockchain, 
      deployedContractType, 
      newDeployContractName,
      deployedContractIsMain, 
      activateDeployedContract, newDeployContractSymbol,
      newDeployContractUri, 
      mentaport, trackEvent, fetchDataAndReset]);

  // const onSubmitPreexistingContract = useCallback(async () => {
  //   if (submittingPreexistingContract) {
  //     return;
  //   }
  //   if (!newContractEnvironment || !newcontractBlockchain || !newContractType) {
  //     setError('Please select an environment, blockchain, and contract type');
  //     return;
  //   }

  //   if (!newContractAddress || !isValidContractAddress(newContractAddress) || !newContractName) {
  //     setError('Please enter a valid contract address and name');
  //     return;
  //   }

  //   // Add the contract
  //   const addData: AddContractArgs = {
  //     contractType: newContractType,
  //     blockchain: newcontractBlockchain,
  //     environment: newContractEnvironment,
  //     isMain: newContractIsMain,
  //     status: 'Active',
  //     address: newContractAddress,
  //     name: newContractName,
  //   };

  //   console.log('addData', addData);
  //   try {
  //     setSubmittingPreexistingContract(true);
  //     const response = await dashboardApi?.addContract(addData);
  //     console.log('add response', response);
  //     if (response.status) {
  //       trackEvent(AnalyticEvent.UploadNewContract, {
  //         ...addData
  //       });
  //       await fetchDataAndReset();
  //     } else {
  //       throw new Error('Error adding contract');
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //     setError('Error adding contract');
  //   } finally {
  //     setSubmittingPreexistingContract(false);
  //   }
  // }, [
  //   newContractEnvironment,
  //   newcontractBlockchain,
  //   newContractType,
  //   newContractAddress,
  //   newContractIsMain,
  //   submittingPreexistingContract,
  //   setSubmittingPreexistingContract,
  // ]);

  const CustomCertificateContractCellRenderer: React.FC<
    CustomCellRendererProps
  > = props => {
    const [sendingRequest, setSendingRequest] = useState<"makeMain" | "pause/active" | "archive">();
    
    const onMakeMainClick = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('make main', contract);
      const updateData: IUpdateContract = {
        contractId: contract.contractId,
        contractType: ContractType.Certificates,
        status: ContractStatus.Active,
        isMain: !contract.isMain,
      }

      try {
        setSendingRequest("makeMain");
        const response = await mentaport?.updateContract(updateData);
        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(AnalyticEvent.MakeContractMain, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.contractId);
      } finally {
        setSendingRequest(undefined);
      }
    }, [sendingRequest,]);
    
    const onPauseOrActive = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('pause or active', contract);
      if (contract.status === 'Archive') {
        return
      }

      try {
        setSendingRequest("pause/active");
        const newStatus = (contract.status === ContractStatus.Active)? ContractStatus.Paused : ContractStatus.Active;

        const response = await mentaport?.updateContractStatus(contract, newStatus);
        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(`${contract.status === 'Active' ? 'Pause' : 'Activate'} Contract` as AnalyticEvent, contract);
        await fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.contractId)
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      sendingRequest
    ]);

    const onArchive = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('archive', contract);
      if (contract.status === 'Archive') {
        return
      }
      try {
        setSendingRequest("archive");
        const response = await mentaport?.updateContractStatus(contract, ContractStatus.Archive);

        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract ' + contract.contractId);
        }
        trackEvent(AnalyticEvent.ArchiveContract, contract);
        await fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error archiving contract' + contract.address)
      } finally {
        setSendingRequest(undefined);
      }
    }, [sendingRequest]); //[fetchDataAndReset]);

    return (
      <CustomCellRenderer
        onArchive={onArchive}
        onMakeMainClick={onMakeMainClick}
        onPauseOrActivate={onPauseOrActive}
        sendingRequest={sendingRequest}
        {...props}
      />
    );
  };

  const CustomRewardsContractCellRenderer: React.FC<
    CustomCellRendererProps
  > = props => {
    const [sendingRequest, setSendingRequest] = useState<"makeMain" | "pause/active" | "archive">();
    
    const onMakeMainClick = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('make main', contract);
      const updateData: IUpdateContract = {
        contractId: contract.contractId,
        contractType: ContractType.Certificates,
        status: ContractStatus.Active,
        isMain: !contract.isMain,
      }

      try {
        setSendingRequest("makeMain");
        const response = await mentaport?.updateContract(updateData);
        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(AnalyticEvent.MakeContractMain, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.contractId);
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      sendingRequest,
    ]);
    
    const onPauseOrActive = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('pause or active', contract);
      if (contract.status === 'Archive') {
        return
      }

      try {
        setSendingRequest("pause/active");
        const newStatus = (contract.status === ContractStatus.Active)? ContractStatus.Paused : ContractStatus.Active;

        const response = await mentaport?.updateContractStatus(contract, newStatus);
        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(`${contract.status === 'Active' ? 'Pause' : 'Activate'} Contract` as AnalyticEvent, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.contractId)
      } finally {
        setSendingRequest(undefined);
      }
    }, [
       sendingRequest
    ]);

    const onArchive = useCallback(async (contract: IContract) => {
      if (sendingRequest) {
        return;
      }
      console.log('archive', contract);
      if (contract.status === 'Archive') {
        return
      }
      try {
        setSendingRequest("archive");
        const response = await mentaport?.updateContractStatus(contract, ContractStatus.Archive);
        console.log('update response', response);
        if (!response || !response.status) {
          throw new Error('Error archiving contract ' + contract.contractId);
        }
        trackEvent(AnalyticEvent.ArchiveContract, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error archiving contract' + contract.address)
      } finally {
        setSendingRequest(undefined);
      }
    }, [sendingRequest]);

    return (
      <CustomCellRenderer
        onArchive={onArchive}
        onMakeMainClick={onMakeMainClick}
        onPauseOrActivate={onPauseOrActive}
        sendingRequest={sendingRequest}
        {...props}
      />
    );
  };

  const dateValueFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
      const date = new Date(params.value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return null;
  }

  const renderBlockchainIcon = (blockchain: string) => {
    const iconMap: any = {
      ethereum: LocalIcons.EthereumLogo,
      base: LocalIcons.BaseLogo,
      polygon: LocalIcons.PolygonLogo,
      sui: LocalIcons.SuiLogo,
      none: LocalIcons.MentaportIcon,
    };
    return (
      <Tooltip title={blockchain}>
        <Image
          margin="0 auto"
          borderRadius={100}
          width={'1.5rem'}
          height={'1.5rem'}
          src={iconMap[blockchain]}
          alt={blockchain}
        />
      </Tooltip>
    );
  };

  const certificateColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (certificateContracts?.length) {
      Object.keys(certificateContracts[0]).forEach(key => {

        tableColumns.push({
          title: key,
          id: key,
          valueFormatter: key === 'updatedAt' ? dateValueFormatter : undefined,
        });
      });

      tableColumns.push({
        title: 'Actions',
        id: 'actions',
        customRenderer: CustomCertificateContractCellRenderer,
        width: 290
      });

      const blockchainColumn = tableColumns.find(
        (column) => column.id === 'blockchain'
      );
      if (blockchainColumn) {
        blockchainColumn.customRenderer = (props) => {
          return (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              {renderBlockchainIcon(props.value)}
            </Flex>
          );
        };
      }
    }

    return tableColumns;
  }, [certificateContracts]);

  const rewardsColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (rewardsContracts?.length) {
      Object.keys(rewardsContracts[0]).forEach(key => {
        tableColumns.push({
          title: key,
          id: key,
          type: 'string',
          valueFormatter: key === 'updateTimestamp' ? dateValueFormatter : undefined,
        });
      });

      tableColumns.push({
        title: 'Actions',
        id: 'actions',
        customRenderer: CustomRewardsContractCellRenderer,
        width: 290
      });

      const blockchainColumn = tableColumns.find(
        (column) => column.id === 'blockchain'
      );
      if (blockchainColumn) {
        blockchainColumn.customRenderer = (props) => {
          return (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              {renderBlockchainIcon(props.value)}
            </Flex>
          );
        };
      }
    }

    return tableColumns;
  }, [rewardsContracts]);

  return (
    <ManagementPage
      title={'Blockchain Admin'}
      path={'certificateAnalytics'}
      isLoading={isLoading}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      <Stack w={'100%'} spacing={6} p={30}>
        <SimpleGrid columns={2} spacing={10}>
          <Stack spacing={4}>
            <Text fontSize={26} fontWeight={600}>
              Deploy Contract
            </Text>
            <HStack w={'100%'}>
              <Select
                className="SelectContainer"
                // placeholder={ContractType.Certificates}
                value={deployedContractType}
                onChange={onChangeDeployedContractType}
              >
                {
                contractTypeKeys?.map(deployOption => (
                  <option key={deployOption} value={deployOption}>
                    {deployOption}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={BlockChainTypes.placeholder}
                value={deployedcontractBlockchain}
                onChange={onChangeDeployedContractBlockChain}
              >
                {BlockChainTypes.options?.map(blockchainOption => (
                  <option key={blockchainOption.id} value={blockchainOption.id}>
                    {blockchainOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                // placeholder={EnvironmentTypes.placeholder}
                value={deployedContractEnvironment}
                onChange={onChangeDeployedContractEnvironment}
              >
                {EnvironmentTypesKeys?.map(environmentOption => (
                  <option
                    key={environmentOption}
                    value={environmentOption}
                  >
                    {environmentOption}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack w={'100%'}>
              {!deployedContractType && (
                <div style={{
                  height: 40, content: ""
                }}>
                </div>
              )}
               <Input
                  placeholder="Contract Name"
                  value={newDeployContractName}
                  onChange={onChangeDeployContractName}
                />
              {deployedContractType === "rewards" && (
                <Input
                  placeholder="Rewards Contract URI"
                  value={newDeployContractUri}
                  onChange={onChangeDeployContractUri}
                />
              )}
              
              {deployedContractType === "certificates" && (
                <Input
                  placeholder="Contract Symbol"
                  value={newDeployContractSymbol}
                  onChange={onChangeDeployContractSymbol}
                />
              )}
            </HStack>
            <HStack w={'100%'}>
              <Checkbox onChange={onChangeDeployedContractIsMain}>
                Is Main
              </Checkbox>
              <Checkbox
                colorScheme="green"
                onChange={onChangeDeployedContractActivate}
              >
                Activate
              </Checkbox>
            </HStack>
            <Flex w={'100%'}>
              <Button
                w="100%"
                className={`Button Warning ${submittingDeployContract ? 'Disabled' : ''}`}
                onClick={onSubmitDeployContract}
                isLoading={submittingDeployContract}
                disabled={submittingDeployContract || submittingPreexistingContract}
              >
                {`${submittingDeployContract ? "Deploying..." : "Deploy New Contract"}`}
              </Button>
            </Flex>
          </Stack>

          {/* <Stack spacing={4}>
            <Text fontSize={26} fontWeight={600}>
              Add Pre-Existing Contracts
            </Text>
            <HStack w={'100%'}>
              <Select
                className="SelectContainer"
                placeholder={DeployTypes.placeholder}
                value={newContractType}
                onChange={onChangeNewContractType}
              >
                {DeployTypes.options?.map(deployOption => (
                  <option key={deployOption.id} value={deployOption.id}>
                    {deployOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={BlockChainTypes.placeholder}
                value={newcontractBlockchain}
                onChange={onChangeNewContractBlockChain}
              >
                {BlockChainTypes.options?.map(blockchainOption => (
                  <option key={blockchainOption.id} value={blockchainOption.id}>
                    {blockchainOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={EnvironmentTypes.placeholder}
                value={newContractEnvironment}
                onChange={onChangeNewContractEnvironment}
              >
                {EnvironmentTypes.options?.map(environmentOption => (
                  <option
                    key={environmentOption.id}
                    value={environmentOption.id}
                  >
                    {environmentOption.title}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack w={'100%'}>
            <Input
                placeholder="Contract Name"
                value={newContractName}
                onChange={onChangeNewContractName}
              />
              <Input
                placeholder="Contract Address"
                value={newContractAddress}
                onChange={onChangeNewContractAddress}
              />
            </HStack>
            <HStack w={'100%'}>
              <Checkbox onChange={onChangeNewContractIsMain}>Is Main</Checkbox>
            </HStack>
            <Flex w={'100%'}>
              <Button
                className="Button Warning"
                w="100%"
                onClick={onSubmitPreexistingContract}
                isLoading={submittingPreexistingContract}
                disabled={submittingDeployContract || submittingPreexistingContract}
              >
                {`${submittingPreexistingContract ? "Adding..." : "Add Pre-Existing Contract"}`}
              </Button>
            </Flex>
          </Stack> */}
        </SimpleGrid>
        <Center>
          <Text color="red">{error}</Text>
        </Center>
        <DataTable
          title="Certificate Contracts"
          columns={certificateColumns}
          data={certificateContracts}
          getRowStyle={(params: any) => {
            if (params.data.status === "Archive") {
              return {
                backgroundColor: 'rgba(255, 193, 193, 0.6)',
              };
            }
            if (params.data.isMain === "true") {
              return {
                backgroundColor: 'rgba(193, 255, 222, 0.6)',
              };
            }
            return {};
          }}
        />
        <DataTable
          title="Rewards Contracts"
          columns={rewardsColumns}
          data={rewardsContracts}
         
          getRowStyle={(params: any) => {
            if (params.data.status === "Archive") {
              return {
                backgroundColor: 'rgba(255, 193, 193, 0.6)',
              };
            }
            if (params.data.isMain === "true") {
              return {
                backgroundColor: 'rgba(193, 255, 222, 0.6)',
              };
            }
            return {};
          }}
        />
      </Stack>
    </ManagementPage>
  );
};
