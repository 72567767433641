import { Button, Flex, Box, Link, Grid, GridItem } from '@chakra-ui/react';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  BlockchainTypes,
  ContractEnvironment,
  ICertificateProject,
  ContractStatus,
} from '@mentaport/certificates-projects';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './ManageProjectPage.scss';
import { OptionList, CustomSelect } from '../../components/commons/OptionList/OptionList';
import { MessageStatus } from '../../services/dialog';
import { useDialog } from '../../hooks/use-dialog';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { Routes } from '../../data/routes';
import { getEditOrCreate } from '../../helpers/pages-helper';
import { getPlaceHolder } from '../../helpers/list-helper';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { CreateInput } from '../../components/commons/CreateInput/CreateInput';

// import {
//   getDefaultBlockchainTypeOptions,
//   getDefaultContractEnvironmentOptions,
//   regenerateOptionsWithNewValue,
// } from '../../helpers/options-helper';

import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';
import { ContractType, IContract, IResults } from '@mentaport/supplement';

export const ManageProjectPage = () => {

  const { projectId, customerId, email } = useParams();
  const { trackEvent } = useAnalytics();
  const dialog = useDialog();
  const mentaportService = useMentaport();
  const navigate = useNavigate();

  const [project, setProject] = useState<ICertificateProject>({
    contractId:'',
    environment: ContractEnvironment.Mezzanine,
    projectName: '',
    blockchain:BlockchainTypes.None,
    createdTimestamp: '',
    customerId: customerId || '',
    ownerWallet: '',
    ownerName: '',
    projectId: projectId || '',
    status: ContractStatus.NonActive,
  });

  const [userEmail, setEmail] = useState(email || '');

  // get projects
  const [contracts, contractsLoading, setContracts] =
    useDataFetcher<IResults<IContract[]>>({
      serviceCall: () => {
        return mentaportService?.getContracts(ContractType.Certificates);
      },
      dependencies: [mentaportService],
      conditionForExecution: mentaportService !== undefined,
      onFinish: response => {
        if (!response?.data) {
          return;
        }
        setContracts(response);
      },
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an error fetching projects.'
        );
        console.error(error);
      },
    });
 
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [_, isLoadingProjectData] = useDataFetcher({
    serviceCall: () => {
      if (!projectId) {
        return;
      }
      return mentaportService?.getCertificateProjectById(projectId, customerId!);
    },
    onFinish: response => {
      if (!response?.data) {
        return;
      }
      setProject(response?.data as ICertificateProject);
    },
    onError: error => {
      dialog.notify(
        MessageStatus.Error,
        'Project fetching',
        error.data?.message
      );
      navigate(-1);
    },
    dependencies: [mentaportService],
    conditionForExecution:
    projectId !== undefined && mentaportService !== undefined,
  });

  
  const handleEditSubmitForm = async () => {
    try {
      setIsLoading(true);
      //TODO
      const _updatedProject = await mentaportService?.updateProject(
      {
          projectId:project.projectId,
          contractId: project.contractId,
          projectName: project.projectName,
          ownerWallet: project.ownerWallet,
          ownerName: project.ownerName,
          projectBaseURI: project.projectBaseURI
      });
      trackEvent(`${getEditOrCreate(false)} Project` as AnalyticEvent, {
        project: {..._updatedProject },
      });
      dialog.notify(
        MessageStatus.Success,
        `${getEditOrCreate(!projectId)} Project`,
        'Your Project has been edited.'
      );
      navigate(-1);
    } catch (error: any) {
      dialog.notify(
        MessageStatus.Error,
        `${getEditOrCreate(!projectId)} Project`,
        error?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateSubmitForm = async () => {
    try {
      setIsLoading(true);
      if (!project.contractId || project.contractId === '') {
        dialog.notify(
          MessageStatus.Error,
          `${getEditOrCreate(!projectId)} Project`,
          'Please select a contract.'
        );
        return;
      }
    
      const newProject =
        await mentaportService?.createNewProject({
          contractId: project.contractId!,
          projectName: project.projectName,
          email: userEmail,
          ownerWallet: project.ownerWallet,
          ownerName: project.ownerName,
          projectBaseURI: project.projectBaseURI,
          customerId: project.customerId
        });
      trackEvent(`${getEditOrCreate(false)} Project` as AnalyticEvent, {
        project: {
          ...newProject,
          projectId,
        },
      });
      
      navigate(-1);
      
      dialog.notify(
        MessageStatus.Success,
        `${getEditOrCreate(!projectId)} Project`,
        'Your Project is ready.'
      );
    } catch (error: any) {
      dialog.notify(
        MessageStatus.Error,
        `${getEditOrCreate(!projectId)} Project`,
        error?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  };


  const isFormReadyForSubmission = () => {
    if (project.contractId !== "") { return true}
    return false
  };

  const renderContractSection = () => {
    return (
      <>
        <Box className="Divider"></Box>
        <Box className="NewContractPageOptionList" w={'100%'}>
        <CustomSelect
          onChange={event => {
            setProject({...project, contractId:event.currentTarget.value.split('|')[0]});
          }}
          placeholder={ 'Select Contract'}
          // defaultValue={projectDefaultValue}
        >
          {contracts?.data?.map(contract => (
            <option
              key={contract.contractId}
              value={contract.contractId + '|' + project.environment}
            >
              {contract.name}
            </option>
          ))}
        </CustomSelect>
          {/* <OptionList
            sectionTitle="Blockchain"
            options={contracts}
            isLoading={isLoading || contractsLoading}
            onSelectValue={option => {
              setContract({
                ...contract,
                blockchain: option.value as BlockchainTypes,
              });
            }}
          /> */}
        </Box>
        
      </>
    );
  };

  const renderCreateProject = () => {
    return (
      <Flex className="NewContractPage" alignItems={'center'}>
        <Box className="Container Left">
          <CreateInput
            isDisabled={contractsLoading}
            title="Project Name *"
            value={project.projectName}
            onChange={value => {
              setProject({ ...project, projectName: value });
            }}
          />
           <CreateInput
            isDisabled={contractsLoading}
            title="User (public) name *"
            value={project.ownerName || ''}
            onChange={value => {
              setProject({ ...project, ownerName: value });
            }}
          />
          <CreateInput
            isDisabled={contractsLoading}
            title="Email (optional, add email or wallet)"
            value={userEmail || ''}
            onChange={value => {
              setEmail(value);
            }}
          />
          <CreateInput
            isDisabled={contractsLoading}
            title="Owner's wallet address (optional, add email or wallet)"
            value={ project.ownerWallet ?? ''}
            onChange={value => {
              setProject({ ...project, ownerWallet: value });
            }}
          />
          <CreateInput
            isDisabled={contractsLoading}
            title="Project URL (optional)"
            value={project.projectBaseURI || ''}
            onChange={value => {
              setProject({ ...project, projectBaseURI: value });
            }}
          />
          {renderContractSection()}
          
          <Box className="Divider"></Box>
          <Box className="ActionsSection">
            <Button
              className="CreateContract"
              isDisabled={!isFormReadyForSubmission()}
              variant={'pink'}
              onClick={() => {
                handleCreateSubmitForm();
              }}
            >
              Add Project
            </Button>
            <Button
              className="Cancel"
              variant={'solid'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>

        <Box className="Container Right">
          <Box className="TextSection">
            <Box className="Title">Projects</Box>
            <Box className="Definition">
              todo add info .
            </Box>
          </Box>

          <Box className="ActionsSection">
            <Button className="GoToDocs" variant={'pink'}>
              <Link
                href="https://docs.mentaport.xyz/Blockchain/Contracts"
                isExternal
              >
                Go to Docs
              </Link>
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  };
  const renderEditProject = () => {
    return (
      <Flex className="NewContractPage" alignItems={'center'}>
        <Box className="Container Left">
          <CreateInput
            isDisabled={contractsLoading}
            title="Project Name *"
            value={project.projectName}
            onChange={value => {
              setProject({ ...project, projectName: value });
            }}
          />
           <CreateInput
            isDisabled={contractsLoading}
            title="User public name *"
            value={project.ownerName || ''}
            onChange={value => {
              setProject({ ...project, ownerName: value });
            }}
          />
          <CreateInput
            isDisabled={contractsLoading}
            title="Owner's wallet address (optional, add email or wallet)"
            value={ project.ownerWallet ?? ''}
            onChange={value => {
              setProject({ ...project, ownerWallet: value });
            }}
          />
          <CreateInput
            isDisabled={contractsLoading}
            title="Project URL (optional)"
            value={project.projectBaseURI || ''}
            onChange={value => {
              setProject({ ...project, projectBaseURI: value });
            }}
          />
          
          <Box className="Divider"></Box>
          <Box className="ActionsSection">
            <Button
              className="CreateContract"
              isDisabled={isFormReadyForSubmission()}
              variant={'pink'}
              onClick={() => {
                handleEditSubmitForm();
              }}
            >
              Edit Project
            </Button>
            <Button
              className="Cancel"
              variant={'solid'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>

        <Box className="Container Right">
          <Box className="TextSection">
            <Box className="Title">Projects</Box>
            <Box className="Definition">
              todo add info .
            </Box>
          </Box>

          <Box className="ActionsSection">
            <Button className="GoToDocs" variant={'pink'}>
              <Link
                href="https://docs.mentaport.xyz/Blockchain/Contracts"
                isExternal
              >
                Go to Docs
              </Link>
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  };

  const buildTitle = () => {
    let projectNameStr = project.projectName ?? '';
    if (projectNameStr !== '') {
      projectNameStr = "'" + projectNameStr + "'";
    }
    const suffix = project.projectName.endsWith('project') || project.projectName.endsWith('Project')
      ? '' : 'project';
    if (customerId) {
      return `${getEditOrCreate(
        !projectId
      )} ${projectNameStr} ${suffix} user - ${customerId}`;
    }
    return `${getEditOrCreate(!projectId)} my ${projectNameStr} ${suffix}`;
  };

  return (
    <ManagementPage
      title={buildTitle()}
      path={''}
      hideBackButton={true}
      isLoading={false}
      previousLocation={Routes.Contracts}
      placeholder={getPlaceHolder()}
    >
      {projectId ? renderEditProject() : renderCreateProject()}
    </ManagementPage>
  );
};
