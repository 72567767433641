import { MentaportService } from '../services/mentaport-service';
import { useState, useEffect } from 'react';

import { MentaportSupplementSDK } from "@mentaport/supplement";
import { CertificateProjectsSDK } from "@mentaport/certificates-projects";

import { useAtomApiKeys } from '../services/jotai-store-service'

export function useMentaport() {
  const [mentaport, setMentaport] = useState<MentaportService|null>(null);
  const [apiKey, isApiKeyLoading] = useAtomApiKeys();
 
  useEffect(() => {
    const loadMentaport = async () => {
      if (isApiKeyLoading || !apiKey) {return;}
      setMentaport(new MentaportService(new MentaportSupplementSDK(apiKey), new CertificateProjectsSDK(apiKey)));
    };
    loadMentaport();
  }, [apiKey, isApiKeyLoading]);
  return mentaport;
}
