import { useCallback, useEffect, useMemo, useState } from 'react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { getPlaceHolder } from '../../helpers/list-helper';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import './ScannersControlPage.scss';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import React from 'react';
import { useDashboardApi } from '../../hooks/use-dashboard-api';
import {
  ScannerEnvironment,
  ScannerSiteType
} from '../../data/scanner-responses';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { DeploymentInProgressStatuses, DeploymentRecord, ScannerResponse } from '../../data/scanner-responses';
import { useAtomApiKeys } from '../../services/jotai-store-service';

type SelectOption = {
  id: string;
  title: string;
};
type SelectData = {
  placeholder: string;
  options: SelectOption[];
};

type ScannerAction =
  | 'instanceId'
  | 'owner'
  | 'site'
  | 'deleteInstanceId'
  | 'create'
  | 'deploy'
  | 'checkDeploymentStatus'
  | 'fetchDeploymentsByOwner'
  | 'fetchDeploymentsBySite'
  | 'fetchDeploymentsFromInstanceId';

const ScannerActionOptions: SelectData = {
  placeholder: 'Select An Action',
  options: [
    {
      id: 'create',
      title: 'Deploy a New Scanner',
    },
    {
      id: 'instanceId',
      title: 'Fetch Scanner by Instance Id',
    },
    {
      id: 'owner',
      title: 'Fetch Scanner by Owner Name',
    },
    {
      id: 'site',
      title: 'Fetch Scanner by Site Type',
    },
    {
      id: 'deleteInstanceId',
      title: 'Delete Scanner by Instance Id',
    },
    {
      id: 'checkDeploymentStatus',
      title: 'Check Deployment Record by Deployment ID',
    },
    {
      id: 'fetchDeploymentsByOwner',
      title: 'Fetch Scanner Deployment Records by Owner',
    },
    {
      id: 'fetchDeploymentsBySite',
      title: 'Fetch Scanner Deployment Records by Site',
    },
    {
      id: 'fetchDeploymentsFromInstanceId',
      title: 'Fetch Scanner Deployment Records by Instance Id',
    },
  ],
};

const defaultEnvironment: ScannerEnvironment = {
  EMAIL: '',
  USERNAME: '',
  PASSWORD: '',
  VERIFY_API_URL: 'https://verify.mentaport.xyz/dev',
  VERIFY_API_KEY: '',
  SCANNER_SLEEP_TIME_SECS_MIN: '',
  HEADLESS: true,
  DOCKERIZED: true,
  USE_ENCRYPTED_CREDS: false,
  SCANNER_SLEEP_TIME_SECS_MAX: '',
  DOCKER_IMAGE: '',
  SITE: 'youtube',
  FOLLOWED_ACCOUNTS_LIST: [],
};

enum SiteType {
  x = 'twitter',
  youtube = 'youtube',
  youtubeMusic = 'youtube-music',
  spotify = 'spotify',
  soundcloud = 'soundcloud',
  tiktok = 'tiktok',
}

const SiteOptions: SelectData = {
  placeholder: 'Site',
  options: [
    {
      id: SiteType.x,
      title: 'X',
    },
    {
      id: SiteType.youtube,
      title: 'YouTube',
    },
    {
      id: SiteType.youtubeMusic,
      title: 'YouTube Music',
    },
    {
      id: SiteType.spotify,
      title: 'Spotify',
    },
    {
      id: SiteType.soundcloud,
      title: 'SoundCloud',
    },
    {
      id: SiteType.tiktok,
      title: 'TikTok',
    },
  ],
};

export const ScannersControlPage = () => {
  const dashboardApi = useDashboardApi();
  const [error, setError] = useState<string>('');

  const [selectedAction, setSelectedAction] = useState<ScannerAction>();
  const [apiKeys, isLoading] = useAtomApiKeys();
  const [newScannerOwnerName, setNewScannerOwnerName] = useState<string>('');
  const [newScannerInstanceId, setNewScannerInstanceId] = useState<string>('');
  const [showDeploymentStage, setShowDeploymentStage] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState<string>('');
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<string>('');
  const [deleteInstanceId, setDeleteInstanceId] = useState<string>('');
  const [deploymentInstanceId, setDeploymentInstanceId] = useState<string>('');
  const [newScannerSiteType, setNewScannerSiteType] =
    useState<ScannerSiteType>("twitter");
  const [newScannerEnvironment, setNewScannerEnvironment] =
    useState<ScannerEnvironment>({ ...defaultEnvironment });
  const [fetchScannerSiteType, setFetchScannerSiteType] =
    useState<ScannerSiteType>("twitter");
  const [submittingAction, setSubmittingAction] = useState(false);
  const [scannerData, setScannerData] = useState<(ScannerResponse | DeploymentRecord)[]>();
  const [scannerDataTitle, setScannerDataTitle] = useState<string>('');

  const fetchDeploymentsFromInstanceId = useCallback(async () => {
    if (dashboardApi && deploymentInstanceId) {
      try {
        const res = await dashboardApi.getDeploymentsByInstance(deploymentInstanceId);
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error fetching deployments');
      }
    } else {
      setError('Please select an instance id to fetch');
    }
  }, [dashboardApi, deploymentInstanceId]);

  const fetchDeploymentsByOwner = useCallback(async () => {
    if (dashboardApi && newScannerOwnerName) {
      try {
        const res = await dashboardApi.getDeploymentsByOwner(newScannerOwnerName);
        if (!Array.isArray(res)) {
          setError('Error fetching deployments');
        }
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error fetching deployments');
      }
    } else {
      setError('Please select an owner to fetch');
    }
  }, [dashboardApi, newScannerOwnerName]);

  const fetchDeploymentsBySite = useCallback(async () => {
    if (dashboardApi && fetchScannerSiteType) {
      try {
        const res = await dashboardApi.getDeploymentsBySite(fetchScannerSiteType);
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error fetching deployments');
      }
    } else {
      setError('Please select a site type to fetch');
    }
  }, [fetchScannerSiteType, dashboardApi]);

  const fetchDeploymentFromDeploymentId = useCallback(async () => {
    if (dashboardApi && selectedDeploymentId) {
      try {
        const res = await dashboardApi.getDeploymentStatus(selectedDeploymentId);
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error fetching deployment');
      }
    } else {
      setError('Please select a deployment id to fetch');
    }
  }, [dashboardApi, selectedDeploymentId]);


  const fetchUserScannerInstances = useCallback(async () => {
    if (dashboardApi) {
      try {
        const res = await dashboardApi.getScannerByOwner(newScannerOwnerName);
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error fetching scanners');
      }
    } else {
      setError('Please select an owner to fetch');
    }
  }, [dashboardApi, newScannerOwnerName]);

  const fetchAllSiteScanners = useCallback(
    async (scannerType?: ScannerSiteType) => {
      const scannerTypeToUse = scannerType || fetchScannerSiteType;
      if (dashboardApi && scannerTypeToUse) {
        try {
          const res = await dashboardApi.getScannerBySite(scannerTypeToUse);
          return res;
        } catch (error) {
          console.log('error', error);
          setError('Error fetching scanners');
        }
      } else {
        setError('Please select a site type to fetch');
      }
    },
    [fetchScannerSiteType, dashboardApi]
  );

  const deleteScannerFromInstanceId = useCallback(async () => {
    if (dashboardApi && deleteInstanceId) {
      try {
        const res = await dashboardApi.deleteInstanceByInstanceId(
          deleteInstanceId
        );
        return res;
      } catch (error) {
        console.log('error', error);
        setError('Error deleting scanner');
      }
    } else {
      setError('Please select an instance id to delete');
    }
  }, [deleteInstanceId, dashboardApi]);

  const fetchScannerFromInstanceId = useCallback(async () => {
    if (dashboardApi && selectedInstanceId) {
      try {
        const res = await dashboardApi.getScannerByInstance(selectedInstanceId);
        return res;
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setError('Please select an instance id to fetch');
    }
  }, [selectedInstanceId, dashboardApi]);

  const deployScanner = useCallback(
    async (instanceId: string, environment: ScannerEnvironment) => {
      if (dashboardApi && instanceId) {
        const res = await dashboardApi.deployScanner({
          instanceId: instanceId,
          environment_variables: environment,
        });
        return res;
      }
    },
    [dashboardApi]
  );

  const createNewScanner = useCallback(async () => {
    if (dashboardApi && newScannerSiteType && newScannerOwnerName) {
      try {
        const res = await dashboardApi.createNewScanner({
          site: newScannerSiteType,
          ownerName: 'test',
        });
        if (res.instance_id) {
          setNewScannerInstanceId(res.instance_id);
          await new Promise(resolve => setTimeout(resolve, 3 * 60 * 1000));
          setShowDeploymentStage(true);
        }
      } catch (error) {
        console.log('error', error);
        setError('Error creating scanner');
      }
    } else {
      setError('Please select a site and owner name to deploy');
      console.log(newScannerSiteType, newScannerOwnerName, dashboardApi);
    }
  }, [dashboardApi, newScannerSiteType, newScannerOwnerName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
    }, 4 * 1_000);

    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    if (!isLoading && apiKeys.length) {
      setNewScannerEnvironment(prev => ({
        ...prev,
        VERIFY_API_KEY: apiKeys,
      }))
    }
  }, [
    isLoading,
    apiKeys
  ])

  useEffect(() => {
    setScannerData(undefined);
    setScannerDataTitle('');
    resetDeleteScannerFromInstanceIdVariables();
    resetFetchScannerByInstanceIdVariables();
    resetFetchAllSiteScannersVariables();
    resetFetchScannerByOwnerVariables();
    resetNewScannerVariables();
    resetNewScannerEnvironmentVariables();
  }, [selectedAction]);

  useEffect(() => {
    if (dashboardApi) {
      //
    }
  }, [dashboardApi]);

  const onChangeSelectedAction = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedAction(event.target.value as ScannerAction);
    },
    [setSelectedAction]
  );

  const onChangeFetchByInstanceIdValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedInstanceId(event.target.value);
    },
    [setSelectedInstanceId]
  );

  const onChangeDeleteInstanceIdValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeleteInstanceId(event.target.value);
    },
    [setDeleteInstanceId]
  );

  const onChangeFetchByOwnerValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // we can have a limit of 8 characters for the owner name, trim if needed
      const name = event.target.value;
      setNewScannerOwnerName(name.length > 8 ? name.slice(0, 8) : name);
    },
    []
  );

  const onChangeFetchSiteType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setFetchScannerSiteType(event.target.value as ScannerSiteType);
    },
    []
  );

  const onChangeNewScannerSiteType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const siteType = event.target.value as ScannerSiteType;
      setNewScannerSiteType(siteType);
    },
    []
  );

  const onChangeNewScannerOwnerName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value;
      setNewScannerOwnerName(name.length > 8 ? name.slice(0, 8) : name);
    },
    []
  );

  const resetNewScannerVariables = useCallback(() => {
    setNewScannerSiteType('twitter');
    setNewScannerOwnerName('');
    setNewScannerEnvironment({} as ScannerEnvironment);
    setNewScannerInstanceId('');
    setShowDeploymentStage(false);
  }, []);

  const resetNewScannerEnvironmentVariables = useCallback(() => {
    setNewScannerEnvironment({
      ...defaultEnvironment,
      VERIFY_API_KEY: apiKeys
    });
  }, []);

  const resetFetchScannerByInstanceIdVariables = useCallback(() => {
    setSelectedInstanceId('');
  }, []);

  const resetFetchScannerByOwnerVariables = useCallback(() => {
    setNewScannerOwnerName('');
  }, []);

  const resetFetchAllSiteScannersVariables = useCallback(() => {
    setFetchScannerSiteType('twitter');
  }, []);

  const resetDeleteScannerFromInstanceIdVariables = useCallback(() => {
    setDeleteInstanceId('');
  }, []);

  const selectedActionValue = useMemo(() => {
    switch (selectedAction) {
      case 'deleteInstanceId':
        return deleteInstanceId;
      case 'instanceId':
        return selectedInstanceId;
      case 'owner':
        return newScannerOwnerName;
      case 'site':
        return fetchScannerSiteType;
      case 'checkDeploymentStatus':
        return selectedDeploymentId;
      case 'fetchDeploymentsByOwner':
        return newScannerOwnerName;
      case 'fetchDeploymentsBySite':
        return fetchScannerSiteType;
      case 'fetchDeploymentsFromInstanceId':
        return deploymentInstanceId;
    }
  }, [
    selectedAction,
    deleteInstanceId,
    selectedInstanceId,
    newScannerOwnerName,
    fetchScannerSiteType,
    selectedDeploymentId,
    deploymentInstanceId,
  ]);

  const onSubmitAction = useCallback(async () => {
    setSubmittingAction(true);
    let scannerData: (ScannerResponse | DeploymentRecord)[] = [];
    let scannerDataTitle = '';
    if (newScannerInstanceId) {
      await deployScanner(newScannerInstanceId, newScannerEnvironment);
    }
    switch (selectedAction) {
      case 'deleteInstanceId':
        await deleteScannerFromInstanceId();
        scannerDataTitle =
          'Deleted Scanner with Instance Id: ' + deleteInstanceId;
        break;
      case 'instanceId':
        const instanceRes = await fetchScannerFromInstanceId();
        if (instanceRes) {
          scannerData = instanceRes;
          scannerDataTitle = 'Scanner with Instance Id: ' + selectedInstanceId;
        }
        break;
      case 'owner':
        const ownerRes = await fetchUserScannerInstances();
        if (ownerRes) {
          scannerData = ownerRes;
          scannerDataTitle = 'Scanners for Owner: ' + newScannerOwnerName;
        }
        break;
      case 'site':
        const siteRes = await fetchAllSiteScanners();
        if (siteRes) {
          scannerData = siteRes;
          const capitaledSite = fetchScannerSiteType
            ? fetchScannerSiteType?.charAt(0).toUpperCase() +
            fetchScannerSiteType?.slice(1)
            : '';
          scannerDataTitle = 'Scanners for Site: ' + capitaledSite;
        }
        break;
      case 'create':
        await createNewScanner();
        break;
      case 'checkDeploymentStatus':
        const checkRes = await fetchDeploymentFromDeploymentId();
        if (checkRes) {
          scannerData = [checkRes];
          scannerDataTitle = 'Deployment with ID: ' + selectedDeploymentId;
        }
        break;
      case 'fetchDeploymentsByOwner':
        const ownerDepRes = await fetchDeploymentsByOwner();
        if (ownerDepRes && Array.isArray(ownerDepRes)) {
          scannerData = ownerDepRes;
          scannerDataTitle = 'Deployments for Owner: ' + newScannerOwnerName;
        }
        break;
      case 'fetchDeploymentsBySite':
        const siteDepRes = await fetchDeploymentsBySite();
        if (siteDepRes && Array.isArray(siteDepRes)) {
          scannerData = siteDepRes;
          const capitaledSite = fetchScannerSiteType
            ? fetchScannerSiteType?.charAt(0).toUpperCase() +
            fetchScannerSiteType?.slice(1)
            : '';
          scannerDataTitle = 'Deployments for Site: ' + capitaledSite;
        }
        break;
      case 'fetchDeploymentsFromInstanceId':
        const instanceDepRes = await fetchDeploymentsFromInstanceId();
        if (instanceDepRes && Array.isArray(instanceDepRes)) {
          scannerData = instanceDepRes;
          scannerDataTitle = 'Deployments for Instance: ' + selectedInstanceId;
        }
        break;
    }

    setSubmittingAction(false);
    setScannerData(scannerData);
    setScannerDataTitle(scannerDataTitle);
  }, [
    selectedAction,
    newScannerInstanceId,
    deleteScannerFromInstanceId,
    fetchScannerFromInstanceId,
    fetchUserScannerInstances,
    fetchAllSiteScanners,
    createNewScanner,
    fetchDeploymentFromDeploymentId,
    fetchDeploymentsByOwner,
    fetchDeploymentsBySite,
    fetchDeploymentsFromInstanceId,
    newScannerOwnerName,
    fetchScannerSiteType,
    selectedInstanceId,
    selectedDeploymentId,
    deploymentInstanceId,
    newScannerEnvironment,
  ]);


  const dateValueFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
      const date = new Date(params.value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return null;
  };

  const getEnvironmentSelectComponent = (
    key: keyof ScannerEnvironment,
    value: any
  ) => {
    let tooltipText = '';
    switch (key) {
      case 'DOCKERIZED':
      case 'HEADLESS':
      case 'USE_ENCRYPTED_CREDS':
        if (key === 'DOCKERIZED') {
          tooltipText = 'Run scanner in a docker container';
        } else if (key === 'HEADLESS') {
          tooltipText =
            'Only set to FALSE if you are running the scanner locally';
        } else {
          tooltipText =
            'Use encrypted credentials pre-populated in the repo code';
        }
        return (
          <Tooltip
            key={key}
            label={tooltipText}
            aria-label="A tooltip"
            placement="top"
            hasArrow
          >
            <Box
              h={'40px'}
              display={'flex'}
              alignItems={'center'}
              style={{
                border: '1px solid #E2E8F0',
                borderRadius: '5px',
                paddingLeft: '10px',
                color: 'lightslategray',
              }}
            >
              <Checkbox
                isChecked={value}
                onChange={e => {
                  setNewScannerEnvironment(prev => {
                    const newEnv = { ...prev, [key]: e.target.checked };
                    return newEnv;
                  });
                }}
              >
                {key}
              </Checkbox>
            </Box>
          </Tooltip>
        );
      case 'SITE':
        return null;
      case 'SCANNER_SLEEP_TIME_SECS_MIN':
      case 'SCANNER_SLEEP_TIME_SECS_MAX':
        if (key === 'SCANNER_SLEEP_TIME_SECS_MIN') {
          tooltipText = 'Minimum time in seconds to sleep between scanner runs';
        } else {
          tooltipText = 'Maximum time in seconds to sleep between scanner runs';
        }
        return (
          <Tooltip
            label={tooltipText}
            aria-label="A tooltip"
            placement="top"
            hasArrow
          >
            <Input
              placeholder={key}
              value={value}
              accept="number"
              onChange={e => {
                setNewScannerEnvironment(prev => {
                  const newEnv = { ...prev, [key]: parseInt(e.target.value) };
                  return newEnv;
                });
              }}
            />
          </Tooltip>
        );
      default:
        if (key === 'EMAIL') {
          tooltipText = 'Email used for sign-in account to use for the scanner';
        } else if (key === 'USERNAME') {
          tooltipText =
            'Username used for sign-in account to use for the scanner';
        } else if (key === 'PASSWORD') {
          tooltipText =
            'Password used for sign-in account to use for the scanner';
        } else if (key === 'VERIFY_API_URL') {
          tooltipText = 'Backend URL to use for verifying content';
        } else if (key === 'VERIFY_API_KEY') {
          tooltipText = 'API key to use for verifying content';
        } else if (key === 'DOCKER_IMAGE') {
          tooltipText = 'Docker image to use for the scanner';
        } else if (key === 'FOLLOWED_ACCOUNTS_LIST') {
          tooltipText = 'List of accounts to follow. Comma or space separated';
        }

        return (
          <Tooltip
            label={tooltipText}
            aria-label="A tooltip"
            placement="top"
            hasArrow
          >
            <Input
              placeholder={key}
              value={value}
              onChange={e => {
                setNewScannerEnvironment(prev => {
                  const newEnv = { ...prev, [key]: e.target.value };
                  return newEnv;
                });
              }}
            />
          </Tooltip>
        );
    }
  };

  const scannerColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (scannerData?.length) {
      Object.keys(scannerData[0]).forEach(key => {
        tableColumns.push({
          // make title from snake case to space separated and capitalize first letter
          title: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          id: key,
          valueFormatter:
            key === 'launch_time' ? dateValueFormatter : undefined,
        });
      });
    }

    return tableColumns;
  }, [scannerData]);

  return (
    <ManagementPage
      title={'Scanners Admin'}
      path={'scanners'}
      isLoading={false}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      <Stack w={'100%'} spacing={6} p={30}>
        <SimpleGrid columns={1} spacing={10}>
          <Stack spacing={4}>
            <Text fontSize={26} fontWeight={600}>
              {ScannerActionOptions.placeholder}
            </Text>
            <Select
              className="SelectContainer"
              placeholder={ScannerActionOptions.placeholder}
              value={selectedAction}
              onChange={onChangeSelectedAction}
            >
              {ScannerActionOptions.options.map(deployOption => (
                <option key={deployOption.id} value={deployOption.id}>
                  {deployOption.title}
                </option>
              ))}
            </Select>
            {selectedAction === 'create' && (
              <Stack spacing={4}>
                <SimpleGrid columns={2} spacing={4}>
                  <Input
                    placeholder="Owner Name"
                    value={newScannerOwnerName}
                    onChange={onChangeNewScannerOwnerName}
                  />
                  <Select
                    className="SelectContainer"
                    placeholder={SiteOptions.placeholder}
                    value={newScannerSiteType}
                    onChange={onChangeNewScannerSiteType}
                  >
                    {SiteOptions.options.map(deployOption => (
                      <option key={deployOption.id} value={deployOption.id}>
                        {deployOption.title}
                      </option>
                    ))}
                  </Select>
                </SimpleGrid>
                {showDeploymentStage && (
                  <Stack spacing={4}>
                    <Text fontSize={26} fontWeight={600}>
                      Scanner Environment Variables for Instance Id:{' '}
                      {newScannerInstanceId}
                    </Text>
                    <SimpleGrid columns={2} spacing={4}>
                      {Object.entries(newScannerEnvironment).map(
                        ([key, value]) =>
                          getEnvironmentSelectComponent(
                            key as keyof ScannerEnvironment,
                            value
                          )
                      )}
                    </SimpleGrid>
                    <Button
                      className="Button Warning"
                      onClick={onSubmitAction}
                      isLoading={submittingAction}
                      disabled={submittingAction}
                    >
                      Deploy Scanner
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
            {selectedAction === 'deleteInstanceId' && (
              <Input
                placeholder="Instance Id"
                value={selectedActionValue}
                onChange={onChangeDeleteInstanceIdValue}
              />
            )}
            {selectedAction === 'instanceId' && (
              <Input
                placeholder="Instance Id"
                value={selectedActionValue}
                onChange={onChangeFetchByInstanceIdValue}
              />
            )}
            {selectedAction === 'owner' && (
              <Input
                placeholder="Owner Name"
                value={selectedActionValue}
                onChange={onChangeFetchByOwnerValue}
              />
            )}
            {selectedAction === 'site' && (
              <Select
                className="SelectContainer"
                placeholder={SiteOptions.placeholder}
                value={selectedActionValue}
                onChange={onChangeFetchSiteType}
              >
                {SiteOptions.options.map(deployOption => (
                  <option key={deployOption.id} value={deployOption.id}>
                    {deployOption.title}
                  </option>
                ))}
              </Select>
            )}
            {selectedAction === 'checkDeploymentStatus' && (
              <Input
                placeholder="Deployment Id"
                value={selectedActionValue}
                onChange={e => setSelectedDeploymentId(e.target.value)}
              />
            )}
            {selectedAction === 'fetchDeploymentsByOwner' && (
              <Input
                placeholder="Owner Name"
                value={selectedActionValue}
                onChange={onChangeFetchByOwnerValue}
              />
            )}
            {selectedAction === 'fetchDeploymentsBySite' && (
              <Select
                className="SelectContainer"
                placeholder={SiteOptions.placeholder}
                value={selectedActionValue}
                onChange={onChangeFetchSiteType}
              >
                {SiteOptions.options.map(deployOption => (
                  <option key={deployOption.id} value={deployOption.id}>
                    {deployOption.title}
                  </option>
                ))}
              </Select>
            )}
            {selectedAction === 'fetchDeploymentsFromInstanceId' && (
              <Input
                placeholder="Instance Id"
                value={selectedActionValue}
                onChange={e => setDeploymentInstanceId(e.target.value)}
              />
            )}
            <Flex w={'100%'}>
              {!showDeploymentStage && (
                <Button
                  className="Button Warning"
                  w="100%"
                  onClick={onSubmitAction}
                  isLoading={submittingAction}
                  disabled={submittingAction}
                >
                  {`${submittingAction ? 'Processing...' : 'Submit'}`}
                </Button>
              )}
            </Flex>
          </Stack>
        </SimpleGrid>
        <Center>
          <Text color="red">{error}</Text>
        </Center>
        <DataTable
          title={scannerDataTitle}
          columns={scannerColumns}
          data={scannerData || []}
          getRowStyle={(params: any) => {
            const status = params.data?.status || params.data?.state.toLowerCase();
            // pending statuses DeploymentInProgressStatuses are subsets of status
            let statusIsPending = false;
            DeploymentInProgressStatuses.forEach(pendingStatus => {
              if (status.includes(pendingStatus)) {
                statusIsPending = true;
              }
            });
            if (status === 'running' || status === 'completed') {
              return {
                backgroundColor: 'rgba(193, 255, 222, 0.6)',
              };
            } else if (statusIsPending) {
              return {
                backgroundColor: 'rgba(255, 222, 168, 0.6)',
              };
            } else {
              return {
                backgroundColor: 'rgba(255, 193, 193, 0.6)',
              }
            }
            return {};
          }}
        />
      </Stack>
    </ManagementPage>
  );
};
