export interface ScannerResponse {
    instance_id: string;
    public_ip: string;
    state: string;
    launch_time: string;
}

export interface DeploymentRecord {
	deploymentId: string;    // Unique identifier, `deploy-${instanceId}-${timestamp}`
	instanceId: string;      // EC2 instance ID
	status: string;         // Current status
	site?: string;          // Associated platform; twitter, tiktok, youtube
	ownerName?: string;     // Instance owner
	error?: string;         // Error information
	createdAt?: string;     // ISO timestamp
	updatedAt: string;      // ISO timestamp
	deletedAt?: string;     // Termination timestamp
}

export type ScannerSiteType = "twitter" | "instagram" | "tiktok" | "youtube" | "youtube-music" | "soundcloud" ;

export interface CreateScannerArgs {
    site: string,
    ownerName: string,
}

export interface ScannerEnvironment {
    EMAIL: string;
    USERNAME: string;
    PASSWORD: string;
    VERIFY_API_URL: string;
    VERIFY_API_KEY: string;
    SCANNER_SLEEP_TIME_SECS_MIN: string;
    HEADLESS: boolean;
    DOCKERIZED: boolean;
    USE_ENCRYPTED_CREDS: boolean;
    SCANNER_SLEEP_TIME_SECS_MAX: string;
    DOCKER_IMAGE: string;
    SITE: ScannerSiteType;
    FOLLOWED_ACCOUNTS_LIST: string[];
}

export interface DeployScannerArgs {
    instanceId: string;
    environment_variables: ScannerEnvironment;
}

export interface GetScannerInstaceByIdArgs {
    instanceId: string;
}

export interface GetScannerInstancesBySiteTypeArgs {
    site: ScannerSiteType;
}

export interface GetScannerInstancesByOwnerNameArgs {
    ownerName: string;
}

export interface DeleteScannerInstanceArgs extends GetScannerInstaceByIdArgs {}
/* 
The possible text for status can be 

*- 'instance created’*
*- 'pending, preparing files to be copied ...’
- 'docker file copied, copying env file ...’
- 'env file copied, copying aws cred ...’
- 'aws creds file copied, starting image install and run steps ...’*

*- 'aws docker health checked, trying to docker login ...’*

*- ' docker login done, cleaning existing docker container ...’*

*- ' old docker containers cleaned, pulling docker image ...’*

*- ' docker image pulled, trying to start container ...’*

*-' docker container started, wait to verify successfully running ...’*

*-'completed'* 

*- ‘failed’, an error field will be included in this case*

*- ‘notstarted’*
*/
export enum DeploymentStatus {
	InstanceCreated = 'instance created',
	Pending = 'pending, preparing files to be copied',
	DockerFileCopied = 'docker file copied, copying env file',
	EnvFileCopied = 'env file copied, copying aws cred',
	AwsCredsFileCopied = 'aws creds file copied, starting image install and run steps',
	AwsDockerHealthChecked = 'aws docker health checked, trying to docker login',
	DockerLoginDone = 'docker login done, cleaning existing docker container',
	OldDockerContainersCleaned = 'old docker containers cleaned, pulling docker image',
	DockerImagePulled = 'docker image pulled, trying to start container',
	DockerContainerStarted = 'docker container started, wait to verify successfully running',
	Completed = 'completed',
	Failed = 'failed',
	NotStarted = 'notstarted'
}

export const DeploymentInProgressStatuses = [
	DeploymentStatus.InstanceCreated,
	DeploymentStatus.Pending,
	DeploymentStatus.DockerFileCopied,
	DeploymentStatus.EnvFileCopied,
	DeploymentStatus.AwsCredsFileCopied,
	DeploymentStatus.AwsDockerHealthChecked,
	DeploymentStatus.DockerLoginDone,
	DeploymentStatus.OldDockerContainersCleaned,
	DeploymentStatus.DockerImagePulled,
	DeploymentStatus.DockerContainerStarted
];

export const DeploymentFailedStatuses = [
	DeploymentStatus.Failed,
	DeploymentStatus.NotStarted
];