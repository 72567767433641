import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getPlaceHolder } from '../../helpers/list-helper';
import {
  DatePicker,
  DatePickerValue,
} from '../../components/commons/DatePicker/DatePicker';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import {
  LineChartData,
  LineGraph,
  TooltipPayload,
} from '../../components/commons/Charts/RechartsLine/RechartsLine';
import './ContractsAnalyticsPage.scss';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  ContractType,
  IContractAnalytics,
  IProjectAnalytics,
  IScanMonth,
} from '@mentaport/supplement';
import { isEmpty, isEqual } from 'lodash';

const COLORS = {
  gray: '#8E8E93',
  purple: '#7733FF',
};

const LINE_IDS = { contract: 'contract', project: 'project' };

export const ContractsAnalyticsPage = () => {
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [dateRange, setDateRange] = useState<DatePickerValue>([
    oneMonthAgo,
    currentDate,
  ]);
  const [contractAnalytics, setContractAnalytics] = useState<
    IContractAnalytics[]
  >([]);
  const [projectAnalytics, setProjectAnalytics] = useState<
    IProjectAnalytics[]
  >([]);
  const [graphContractAnalytics, setGraphContractAnalytics] =
    useState<IScanMonth[]>();

  const mentaportService = useMentaport();

  const fetchGraphAnalytics = useCallback(async () => {
  
    if (!mentaportService || projectId === '') return;
    const marshalledDateRange = Array.isArray(dateRange)
      ? dateRange
      : [dateRange, dateRange];
    const queryDateMin = (marshalledDateRange[0] || new Date()).toISOString();
    const queryDateMax = (marshalledDateRange[1] || new Date()).toISOString();
    await new Promise(resolve => setTimeout(resolve, 300));
    const res = await mentaportService?.getProjectIdAnalytics(
      projectId,
      queryDateMin,
      queryDateMax
    );

    const newContractAnalytics = res.data?.perMonth;
    console.log('contract res', res.data);
    if (!isEqual(graphContractAnalytics, newContractAnalytics)) {
      console.log('setting graph contract data', newContractAnalytics);
      setGraphContractAnalytics(newContractAnalytics);
    }
    // x axis is month, y axis = data.count
  }, [mentaportService, dateRange, projectId, graphContractAnalytics]);

  const fetchCertificatesContractAnalytics = useCallback(async () => {

    if (!mentaportService) return;
    const res = await mentaportService?.getContractAnalytics(ContractType.Certificates);

    if (!!res?.data) {
      const contractData = res.data?.contracts;
      const projectData = res.data.projects;

      if (!isEqual(contractData, contractAnalytics))
        setContractAnalytics(contractData);
      if (!isEqual(projectData, projectAnalytics))
        setProjectAnalytics(projectData);
    }

    await new Promise(resolve => setTimeout(resolve, 300));
  }, [contractAnalytics, projectAnalytics, mentaportService]);

  const onUpdateGraph = useCallback(() => {
    fetchGraphAnalytics();
  }, [fetchGraphAnalytics]);

  const onDateRangeChanged = useCallback(
    (newValue: DatePickerValue) => {
      setDateRange(newValue);
      onUpdateGraph();
    },
    [onUpdateGraph]
  );

  const onRefreshData = useCallback(async () => {
    const promises = [fetchGraphAnalytics(), fetchCertificatesContractAnalytics()];
    setIsLoading(true);
    await Promise.all(promises);
    setIsLoading(false);
  }, [fetchGraphAnalytics, fetchCertificatesContractAnalytics]);

  const onResetAll = useCallback(() => {
    setContractId('');
    setProjectId('');
    fetchGraphAnalytics();
  }, [setContractId, fetchGraphAnalytics]);

  const onContractIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setContractId(value);
    },
    [setContractId]
  );
  const onProjectIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setProjectId(value);
    },
    [setProjectId]
  );
  useEffect(() => {
    if (!!mentaportService) {
      onRefreshData();
    }
  }, [mentaportService]);

  const projectColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (projectAnalytics?.length) {
      Object.keys(projectAnalytics[0]).forEach(key => {
        tableColumns.push({
          title: key,
          id: key,
        });
      });
    }

    return tableColumns;
  }, [projectAnalytics]);

  const contractColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (contractAnalytics?.length) {
      Object.keys(contractAnalytics[0]).forEach(key => {
        tableColumns.push({
          title: key,
          id: key,
        });
      });
    }

    return tableColumns;
  }, [contractAnalytics]);

  const xAxisTickFormatter = useCallback((value: any): string => {
    const valueStr = `${value}`;
    switch (valueStr) {
      case '0':
        return 'Jan';
      case '1':
        return 'Feb';
      case '2':
        return 'Mar';
      case '3':
        return 'Apr';
      case '4':
        return 'May';
      case '5':
        return 'Jun';
      case '6':
        return 'Jul';
      case '7':
        return 'Aug';
      case '8':
        return 'Sep';
      case '9':
        return 'Oct';
      case '10':
        return 'Nov';
      case '11':
        return 'Dec';
      default:
        return valueStr;
    }
  }, []);

  const xAxisContractTooltipFormatter = useCallback((value: any): string => {
    return `Minted in contract:`;
  }, []);

  const xAxisProjectTooltipFormatter = useCallback((value: any): string => {
    return `Minted in project:`;
  }, []);

  const yAxisTooltipFormatter = useCallback((value: any) => {
    return value + 'hello';
  }, []);

  const CustomTooltipContent = useCallback((props: any) => {
    const { active, payload } = props;
    const payloads = payload as TooltipPayload[];

    if (!active || !payloads.length) return null;

    return (
      <Box className="CustomTooltip">
        <Stack w={'100%'}>
          {payloads.map((payload, idx) => {
            const { color, payload: data, id } = payload;

            let formatter = xAxisContractTooltipFormatter;
            if (id === LINE_IDS.project) {
              formatter = xAxisProjectTooltipFormatter;
            }

            return (
              <HStack key={idx} alignItems={'flex-end'} gap={2}>
                <Box className="TooltipTitle">
                  <Text color={color}>{formatter(data.x)}</Text>
                </Box>
                <Box className="TooltipValue">
                  <Text color={color}>
                    {typeof data.y === 'string'
                      ? data.y
                      : data.y.toLocaleString()}
                  </Text>
                </Box>
              </HStack>
            );
          })}
        </Stack>
      </Box>
    );
  }, []);

  const lineGraphData: LineChartData[] = [];
  if (!isEmpty(graphContractAnalytics))
    lineGraphData.push({
      color: COLORS.gray,
      id: LINE_IDS.contract,
      xAxisTooltipFormatter: xAxisProjectTooltipFormatter,
      data: !graphContractAnalytics?.length
        ? []
        : graphContractAnalytics.map((entry, idx) => ({
            x: entry.month, // Convert key to number
            y: entry.count,
          })),
    });

  return (
    <ManagementPage
      title={'Contract Analytics'}
      path={'contractAnalytics'}
      isLoading={isLoading}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      <Stack w={'100%'} spacing={6} p={30}>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text fontSize={26} fontWeight={600}>
            Projects Minting
          </Text>
          <HStack>
            <Button
              className="Button"
              onClick={onRefreshData}
              isLoading={isLoading}
            >
              <Text px={4}>Refresh</Text>
            </Button>
            <DatePicker onChange={onDateRangeChanged} value={dateRange} />
          </HStack>
        </Flex>

        <Grid w={'100%'} templateRows={'minmax(auto, 1fr) 3fr'} gap={6}>
          <GridItem rowSpan={2}>
            <Stack>
              <LineGraph
                height={260}
                data={lineGraphData}
                yLabel="Count"
                xLabel="Months"
                tooltipContent={<CustomTooltipContent />}
                xAxisTickFormatter={xAxisTickFormatter}
              />
              <HStack alignItems={'flex-end'} gap={4}>
                <Stack>
                  <Text color={COLORS.gray} fontWeight={600}>
                    ContractId
                  </Text>
                  <Input
                    className="InputContainer"
                    borderRadius={'0 5px 5px 0'}
                    type={'text'}
                    value={contractId}
                    onChange={onContractIdChange}
                  />
                </Stack>
                <Stack>
                  <Text color={COLORS.gray} fontWeight={600}>
                    ProjectId
                  </Text>
                  <Input
                    className="InputContainer"
                    borderRadius={'0 5px 5px 0'}
                    type={'text'}
                    value={projectId}
                    onChange={onProjectIdChange}
                  />
                </Stack>
                <Button className="Button" onClick={onUpdateGraph}>
                  <Text px={4}>Show</Text>
                </Button>
                <Button className="Button Warning" onClick={onResetAll}>
                  <Text px={4}>All/Reset</Text>
                </Button>
              </HStack>
            </Stack>
          </GridItem>
          <GridItem rowSpan={1}>
            <DataTable
              title="Projects"
              data={projectAnalytics || []}
              columns={projectColumns}
              withFiltering
            />
          </GridItem>
          <GridItem rowSpan={1}>
            <DataTable
              title="Blockchain Contracts"
              data={contractAnalytics || []}
              columns={contractColumns}
              withFiltering
            />
          </GridItem>
        </Grid>
      </Stack>
    </ManagementPage>
  );
};
