import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import {
  IResults,
  ContractEnvironment,
  ICertificateProject,
  ContractStatus,
} from '@mentaport/certificates-projects';
import {
  ProjectEnvironmentContent,
  ProjectListComponent,
} from '../../components/commons/ProjectListComponent/ProjectListComponent';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';
import { getPlaceHolder } from '../../helpers/list-helper';
import { useState } from 'react';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';
import { useMentaport } from '../../hooks/use-mentaport';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import _ from 'lodash';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import "./UserProjectsPage.scss";
import { useDashboardApi } from '../../hooks/use-dashboard-api';


export const UserProjectsPage = () => {
  let { customerId, email } = useParams();
  
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const mentaport = useMentaport();
  const dialog = useDialog();
  // const [contractAddress, setContractAddress] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activationLoading, setActivationLoading] = useState(false);
  const [lastClickedProject, setLastClickedProject] = useState<ICertificateProject>();
  const apiClient = useDashboardApi();

  const [projectsResponse, projectsResponseLoading, setProjects] =
    useDataFetcher<IResults<ICertificateProject[]>>({

      serviceCall: () => {
        return mentaport?.getCertificateProjects(undefined, customerId);
      },
      dependencies: [mentaport],
      conditionForExecution: mentaport !== undefined,
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an error fetching projects.'
        );
        console.error(error);
      },
    });

  const handleCreateProject = async () => {
    trackEvent(AnalyticEvent.NavigationToCreateNewProject);
    navigate(`/projects/new/${customerId}/${email}`);
  };

  const handleActivate = async (project: ICertificateProject) => {
    setLastClickedProject(project);
    // if mezzanine:
    // if (project.environment === ContractEnvironment.Mezzanine) {
    //   await handleMezzanineActivate(project);
    //   return;
    // }
    onOpen();
  };

  // const handleMezzanineActivate = async (project: ICertificateProject) => {
  //   try {
  //     // console.log("contract", contract)
  //     const result = await mentaport?.activateMezzanineContract(contract, contract.customerId);
  //     if (result?.status === true) {
  //       updateContractStatus(contract, ContractStatus.Active);
  //       trackEvent(AnalyticEvent.ActivateContractUsers, contract);
  //       dialog.notify(
  //         MessageStatus.Success,
  //         'Mezzanine Contracts',
  //         'The contract has been activated'
  //       );
  //     } else {
  //       dialog.notify(
  //         MessageStatus.Error,
  //         'Mezzanine Contracts',
  //         'There was an issue activating the contract'
  //       );
  //     }
  //   } catch (error) {
  //     dialog.notify(
  //       MessageStatus.Error,
  //       'Mezzanine Contracts',
  //       'There was an error activating the contract'
  //     );
  //   }
  // };

  const handlePauseProject = async (project: ICertificateProject) => {
    try {
      const result = await mentaport?.pauseProject(project.contractId, project.projectId);
      if (result?.status === true) {
        updateProjectStatus(project, ContractStatus.Paused);
        trackEvent(AnalyticEvent.PauseContract, project)
        dialog.notify(
          MessageStatus.Success,
          'Projects',
          'The project has been paused'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an issue pausing the project'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Projects',
        'There was an error pausing the project'
      );
    }
  };

  const handleResumeProject = async (project: ICertificateProject) => {
    try {
      const result = await mentaport?.resumeProject(project.contractId, project.projectId);
      if (result?.status === true) {
        updateProjectStatus(project, ContractStatus.Active);
        trackEvent(AnalyticEvent.ResumeContract, project)
        dialog.notify(
          MessageStatus.Success,
          'Projects',
          'The project has been resumed'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an issue resuming the project'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Contracts',
        'There was an error resuming the contract'
      );
    }
  };

  const updateProjectStatus = (
    project: ICertificateProject,
    newStatus: ContractStatus
  ) => {
    trackEvent(AnalyticEvent.UpdateProject, project);
    if (projectsResponse?.data === undefined) {
      return;
    }
    const projectData = projectsResponse?.data;
    const index = _.findIndex(
      projectData,
      x => x.projectId === project.projectId
    );
    if (index === -1) {
      return;
    }
    projectData[index].status = newStatus;
    setProjects({
      status: true,
      statusCode: 205,
      message: 'Project Activated ',
      data: projectData,
    });
  };

  const getManagementButtons = () => {
    return [
      <Button
        marginLeft={10}
        variant="pink"
        size="sm"
        onClick={() => {
          handleCreateProject();
        }}
      >
        Create Project
      </Button>,
    ];
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        cancelTitle={`Cancel. I'll activate project later`}
        title={'Type of Activation'}
      >
        <Flex direction="column">
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Recommended</Badge>
            <Text fontSize="lg">On Certificate Registry</Text>
            <Text fontSize="m">
              Activate directly in Mentaport Infrastructure
            </Text>
            <Button variant="mint" onClick={handleActivateOnRegistry}>
              Activate
            </Button>
          </Card>
          {/* 
          <Flex
            margin="2rem"
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            justifyContent="center"
          >
         <Text fontSize="lg">OR</Text>
          </Flex>
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Advanced</Badge>
            <Text fontSize="lg">On Existing contract</Text>
            <Text fontSize="m">
              Activate contract in a contract you already own in the blockchain
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={GiMoebiusTriangle} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Contract Address"
                value={contractAddress}
                onChange={(event: any) =>
                  setContractAddress(event.target.value)
                }
              />
            </InputGroup> */}
          {/* <Button
              variant="mint"
              disabled={contractAddress.length === 0}
              opacity={contractAddress.length === 0 ? 0.5 : 1}
              onClick={handleActivateOnAlreadyDeployedContract}
            >
              Activate
            </Button> */}
          {/* </Card> */}

        </Flex>
      </DrawerDialog>
    );
  };


  const handleActivateOnRegistry = async () => {
    try {
      setActivationLoading(true);
      if (lastClickedProject === undefined) {
        return;
      }
      onClose();
      console.log('handleActivateOnRegistry', lastClickedProject, customerId!)
      const result = await apiClient?.activateProject(lastClickedProject.contractId, lastClickedProject.projectId, customerId!);
      if (result === true) {
        updateProjectStatus(lastClickedProject, ContractStatus.Active);
        trackEvent(AnalyticEvent.ActivateProject, lastClickedProject);
        dialog.notify(
          MessageStatus.Success,
          'Projects',
          'The project has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Projects',
          'There was an issue activating the project'
        );
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message ?? "Error in backend."
      dialog.notify(
        MessageStatus.Error,
        'Projects',
        errorMessage
      );
    } finally {
      setActivationLoading(false);
    }
  };

  const getData = (): ProjectEnvironmentContent[] => {
    const groups = _.groupBy(projectsResponse?.data ?? [], x => x.environment);
    return [
      {
        projects: groups[ContractEnvironment.Mainnet] ?? [],
        environmentType: ContractEnvironment.Mainnet,
      },
      {
        projects: groups[ContractEnvironment.Testnet] ?? [],
        environmentType: ContractEnvironment.Testnet,
      },
      {
        projects: groups[ContractEnvironment.Mezzanine] ?? [],
        environmentType: ContractEnvironment.Mezzanine,
      },
      {
        projects: groups[ContractEnvironment.Localhost] ?? [],
        environmentType: ContractEnvironment.Localhost,
      },
    ];
  };

  return (
    <ManagementPage
      title={`Projects - ${email}`}
      buttons={getManagementButtons()}
      path={`users/projects/${customerId}`}
      hideBackButton={true}
      isLoading={projectsResponseLoading}
      placeholder={getPlaceHolder()}
      breadcrumbs={[]}
      previousLocation={'users'}
    >
      {renderSideDrawer()}
      <div className='UserContractsPage'>
      <ProjectListComponent
        managementPageProps={{
          title: ``,
          path: '',
          hideBackButton: true,
          isLoading: projectsResponseLoading,
          placeholder: getPlaceHolder(),
          breadcrumbs: [],
          previousLocation: Routes.Home,
        }}
        complexActivationLoading={activationLoading || isOpen}
        environments={getData()}
        onEditProject={(project: ICertificateProject) => {
          trackEvent(AnalyticEvent.NavigationtoEditContract, project);
          navigate(`/projects/edit/${project.projectId}/${project.customerId}`);
        }}
        onPauseProject={(project: ICertificateProject): Promise<void> => {
          return handlePauseProject(project);
        }}
        onResumeProject={(project: ICertificateProject): Promise<void> => {
          return handleResumeProject(project);
        }}
        onActivateProject={(project: ICertificateProject): Promise<void> => {
          return handleActivate(project);
        }}
        onShowContractAnalytics={(
          contractId: string,
          contractName: string
        ): void => {
          trackEvent(AnalyticEvent.NavigationToContractAnalytics, { contractId, contractName });
          // navigate(
          //   `/contracts/${contractId}/${contractName}/analytics`
          // );
        }}
      />
      </div>
    </ManagementPage>
  )
};


