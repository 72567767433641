import { configuration } from '../../config/configuration';
import { useAuth0 } from '@auth0/auth0-react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { useEffect, useState } from 'react';

export enum AnalyticEvent {
  ResumeContract = 'Resume Contract',
  PauseContract = 'Pause Contract',
  ArchiveContract = 'Archive Contract',
  MakeContractActive = 'Make Contract Active',
  MakeContractMain = 'Make Contract Main',
  UpdateContract = 'Update Contract',
  ActivateProject = 'Activate Project',
  ResumeProject = 'Resume Project',
  PauseProject = 'Pause Project',
  UpdateProject = 'Update Project',

  DeployNewContract = 'Deploy New Contract',
  UploadNewContract = 'Upload New Contract',

  CreateAPIKey = 'Create Api Key',
  CreateRule = 'Create Rule',
  EditRule = 'Edit Rule',
  SetWhoSelectionOnRule = 'Set Who Selection On Rule',
  SetWhenSelectionOnRule = 'Set When Selection On Rule',
  SetWhereSelectionOnRule = 'Set Where Selection On Rule',

  NavigationToCreateNewProject = "Navigate to Create New Project",
  NavigationToProjects = 'Navigation To Projects',
  NavigationToUserProjects = 'Navigation To User Projects',

  NavigationToHome = 'Navigation To Home',
  NavigationToCreateNewContract = 'Navigation To Create New Contract',
  NavigationToContracts = 'Navigation To Contracts',
  NavigationToUsers = 'Navigation To Users',
  NavigationToContractAnalytics = 'Navigation To Contract Analytics',
  NavigationToCertificateAnalytics = 'Navigation To Certificate Analytics',
  NavigationToBlockchainAdmin = 'Navigation To Blockchain Admin',
  NavigationToScanners = 'Navigation To Scanners',
  NavigationToManageContractRules = 'Navigation To Manage Contract Rules',
  NavigationToCreateRule = 'Navigation To Create Rule',
  NavigationToCreateApiKey = 'Navigation To Create Api Key',
  NavigationtoEditContract = 'Navigation To Edit Contract',
}

class AnalyticsSingleton {
  private static instance: AnalyticsBrowser | null = null;
  private static identity: string | undefined;

  static getInstance(): AnalyticsBrowser {
    if (!this.instance) {
      this.instance = AnalyticsBrowser.load({
        writeKey: configuration.june.writeKey,
      }) as AnalyticsBrowser;
    }
    return this.instance;
  }

  static async addIdentify(userId: string, details?: any) {
    if (this.instance) {
      if (this.identity !== userId) {
        await this.instance.identify(userId, {
          details: details,
          environment: configuration.mentaport.stageId,
        });
        this.identity = userId;
      }
    }
  }

  static async trackEvent(eventName: AnalyticEvent, details?: any) {
    if (this.instance) {
      console.log('tracking event:', eventName, details);
      await this.instance.track(eventName, {
        ...details,
        environment: configuration.mentaport.stageId,
      }, {
        context: {
          groupId: configuration.june.groupId,
        }
      });
    }
  }

  static reset() {
    if (this.instance) {
      this.instance.reset();
      this.instance = null;
      this.identity = undefined;
    }
  }
}

export const useAnalytics = (): {
  trackEvent: (eventName: AnalyticEvent, details?: any) => Promise<void>;
  analytics: AnalyticsBrowser | undefined;
} => {
  const analytics = AnalyticsSingleton.getInstance();
  const { user, isAuthenticated } = useAuth0();
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    if (user && isAuthenticated) {
      const newUserId = user?.sub || user?.email || user?.name;
      if (newUserId !== userId) {
        setUserId(newUserId);
      }
    } else {
      if (userId) setUserId(undefined);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    const manageUserIdentity = async () => {
      if (isAuthenticated && userId) {
        await identifyUser(userId);
      } else {
        await resetIdentity();
      }
    };
    manageUserIdentity();
  }, [userId, isAuthenticated, analytics]);

  const identifyUser = async (userId: string, details?: any) => {
    try {
      await AnalyticsSingleton.addIdentify(userId, details);
    } catch (error) {
      console.error(error);
    }
  };

  const resetIdentity = async () => {
    try {
      await AnalyticsSingleton.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const trackEvent = async (eventName: AnalyticEvent, details: any) => {
    try {
      await AnalyticsSingleton.trackEvent(eventName, details);
    } catch (error) {
      console.error(error);
    }
  };

  return { trackEvent, analytics };
};
