import { 
  CertificateProjectsSDK,
  ICertificateProject, 
  ICreateCertProject, 
  IUpdateCertProject,
  IUpdateCertProjectStatus 
} from '@mentaport/certificates-projects';
import {
  ContractType,
  Environment,
  IResults,
  IContract,
  ContractStatus,
  IUpdateContract,
  IContractCreate,
  IContractsAnalytics,
  IProjectIdAnalytics,
  ICertificateAnalytics,
  ICertIdWalletAnalytics,
  MentaportSupplementSDK,
} from '@mentaport/supplement';

import { configuration } from '../../config/configuration';

export class MentaportService {
  supClient: MentaportSupplementSDK;
  projClient: CertificateProjectsSDK;
  constructor(supClient: MentaportSupplementSDK, projClient: CertificateProjectsSDK) {
    this.supClient = supClient;
    this.supClient.setClientEnv(this.getMentaportEnvironment());

    this.projClient = projClient;
    this.projClient.setClientEnv(this.getMentaportEnvironment());
  }

  private getMentaportEnvironment = (): Environment => {
    switch (configuration.mentaport.stageId) {
      case 'development':
        return Environment.DEVELOPMENT;
      case 'staging':
        return Environment.STAGING;
      case 'production':
        return Environment.PRODUCTION;
      default:
        return Environment.STAGING;
    }
  };

  //---------------------------------------------
  // Contract
  public async createNewContract(
    contract: IContractCreate
  ): Promise<IResults<IContract>> {

    const newContract =
      await this.supClient.contractSDK.createContract(contract);
    return newContract;
  }

  public async getContracts(contractType:ContractType):Promise<IResults<IContract[]> > {
    return await this.supClient.contractSDK.getContracts<IContract[]>(contractType);
  }

  public async getContractById(contractId: string, contractType:ContractType) {
    return await this.supClient.contractSDK.getContractById<IContract>(
      contractId,
      contractType
    );
  }

  public async updateContract(updateContract: IUpdateContract) {
    return await this.supClient.contractSDK.updateContractById<IContract>(
      updateContract
    );
  }

  public async updateContractStatus(contract: IContract, status:ContractStatus) {
    return await this.supClient.contractSDK.updateContractStatusById(
      {
      contractId: contract.contractId,
      contractType: contract.contractType,
      status: status,
    });
  }

  //---------------------------------------------
  // Projects
  public async createNewProject(
    newProject: ICreateCertProject
  ): Promise<IResults<ICertificateProject>> {

    return await this.projClient.createNewCertificateProject(newProject);
  }


  public async getCertificateProjects(
    contractId?: string,
    customerId?: string
  ) {
    // console.log(this.projClient)
    return await this.projClient.getProjects(contractId, customerId);
  }

  public async getCertificateProjectById(
    projectId:string,
    customerId?: string
  ) {
    return await this.projClient.getProjectById('', projectId, customerId);
  }
  
  public async updateProject(updateProject: IUpdateCertProject) {
    return await this.projClient.updateProjectById(updateProject);
  }

  public async updateProjectStatus(updateProject: IUpdateCertProjectStatus) {
    return await this.projClient.updateProjectStatusById(updateProject);
  }

  public async pauseProject(contractId: string,projectId:string) {
    return await this.projClient.updateProjectStatusById(
      {
      contractId,
      projectId,
      status: ContractStatus.Paused,
    });
  }
  public async resumeProject(contractId: string,projectId:string) {
    return await this.projClient.updateProjectStatusById(
      {
      contractId,
      projectId,
      status: ContractStatus.Active,
    });
  }

  //---------------------------------------------
  // Analytics
  public async getContractAnalytics(contractType:ContractType):Promise<IResults<IContractsAnalytics>> {
    return await this.supClient.analyticsSDK.getContractAnalytics(contractType);
  }
  public async getProjectIdAnalytics(projectId: string, queryDateMin: string, queryDateMax:string):Promise<IResults<IProjectIdAnalytics>> {
    return await this.supClient.analyticsSDK.getProjectIdAnalytics(
      projectId,
      queryDateMin,
      queryDateMax
    );
  }
  public async getCertificatesAnalytics():Promise<IResults<ICertificateAnalytics>>  {
    return await this.supClient.analyticsSDK.getCertificatesAnalytics();
  }
  public async getCertIdWalletAnalytics(queryDateMin: string, queryDateMax:string, certId: string='na',wallet: string='na'):Promise<IResults<ICertIdWalletAnalytics>> {
    return await this.supClient.analyticsSDK.getCertIdWalletAnalytics(
      certId,
      wallet,
      queryDateMin,
      queryDateMax
    );
  }
  //---------------------------------------------
  // Rules
//   public async createNewRules(rules: IRule[]) {
//     return await this.supClient.rulesSDK.createNewRules(rules);
//   }

//   public async updateRuleById(
//     ruleId: string,
//     contractId: string,
//     rule: IUpdateRule
//   ) {
//     return await this.supClient.rulesSDK.updateRuleById(
//       ruleId,
//       contractId,
//       rule
//     );
//   }

//   public async getRuleById(
//     ruleId: string,
//     contractId: string
//   ): Promise<IResults<IRule>> {
//     return await this.supClient.rulesSDK.getRuleById(contractId, ruleId);
//   }

//   public async updateRule(
//     ruleId: string,
//     contractId: string,
//     rule: IUpdateRule
//   ): Promise<IResults<IUpdateRule>> {
//     return await this.supClient.rulesSDK.updateRuleById(
//       ruleId,
//       contractId,
//       rule
//     );
//   }

//   public async getRules(contractId: string): Promise<IResults<IRule[]>> {
//     return await this.supClient.rulesSDK.getAllRules(contractId);
//   }
}
